<template>
    <div class="purple-container">
        <h1 class="subtitle light"> {{ $t('pricing.header1') }}</h1>
        <p class="subtitle light"> {{ $t('pricing.description') }}</p>
        <div class="pricing-tiles">
            <ui-select class="currencies-dropdown" v-model="selectedCurrency" :options="currencyOptions"></ui-select>
            <ui-grid style="max-width: 1450px;">
                <ui-grid-cell columns="4" align="middle">
                    <div v-shape class="container">
                        <p class="plan">{{ $t('pricing.basic') }}</p>
                        <p class="price">
                            {{ price1 }}
                        </p>
                        <p class="percentaje">
                            {{ fee1 }}
                        </p>
                    </div>
                </ui-grid-cell>
                <ui-grid-cell columns="4" align="middle">
                    <div v-shape class="container">
                        <p class="plan">{{ $t('pricing.standard') }}</p>
                        <p class="price">
                            {{ price2 }}
                        </p>
                        <p class="percentaje">
                            {{ fee2 }}
                        </p>
                    </div>
                </ui-grid-cell>
                <ui-grid-cell columns="4" align="middle">
                    <div v-shape class="container">
                        <p class="plan">{{ $t('pricing.preference') }}</p>
                        <p class="price">
                            {{ price3 }}
                        </p>
                        <p class="percentaje">
                            {{ fee3 }}
                        </p>
                    </div>
                </ui-grid-cell>
            </ui-grid>
        </div>
    </div>
    <h1 class="subtitle"> {{ $t('pricing.calulatorHeader') }}</h1>
    <ui-card class="sim-container" v-shadow.transition="[2, 16]">
        <img class="calculator-img" src="@/assets/home/4.2.svg" height="170" />
        <ui-grid>
            <ui-grid-cell columns="4">
                <ui-textfield style="width: 200px;" outlined input-type="number" v-model="ticketPrice"> {{
            $t('pricing.publicPrice') }} <template #before>
                        <ui-textfield-icon>attach_money</ui-textfield-icon>
                    </template></ui-textfield>
                <span class="operator">x</span>
                <p style="font-size: large;">
                    {{ $t('pricing.feePerTicket', [planName, formattedFee]) }}
                </p>
                <p style="font-weight: bold; font-size: large;">
                    {{ $t('pricing.amountReceived', [formattedPriceWithFee, currencyTotal]) }}
                </p>
            </ui-grid-cell>
            <ui-grid-cell columns="4">
                <ui-textfield style="width: 160px;" outlined input-type="number" v-model="quantity"> {{
            $t('pricing.quantity') }} <template #before>
                        <ui-textfield-icon>numbers</ui-textfield-icon>
                    </template></ui-textfield>
                <span class="operator">=</span>
            </ui-grid-cell>
            <ui-grid-cell columns="4">
                <span class="total">{{ $t('pricing.totalReceived', [total, currencyTotal]) }}<span
                        class="superindex">*</span></span>
            </ui-grid-cell>
        </ui-grid>
        <h4 class="subtitle"> {{ $t('pricing.stripeDisclaimer') }} <a href="https://stripe.com/" target="_blank">
                Stripe
                &#8482;</a>
            <br>
            {{ $t('pricing.stripeDisclaimer2') }} <a href="https://stripe.com/pricing" target="_blank">
                link.</a>
        </h4>
    </ui-card>
    <div class="stripe-container">
        <h1 class="subtitle light"> {{ $t('pricing.ally') }}</h1>
        <div style="max-width: 1280px; margin: 0 auto;">
            <p style="color: white;"> {{ $t('pricing.stripeDescription') }}</p>
            <p style="color: white;"> {{ $t('pricing.stripeDescription2') }}</p>
        </div>
        <payment-methods></payment-methods>
    </div>
</template>

<script>

import currencies from "@/utils/currencies";
import formatters from "@/utils/formatters";
import amounts from "@/utils/amounts";
import PaymentMethods from "@/components/home/PaymentMethods.vue";

export default {
    name: 'TdPricing',
    components: { PaymentMethods },
    data() {
        return {
            selectedCurrency: 0,
            price1: "< $ 999.99 mxn",
            price2: "$ 1000.00 - $ 2999.99 mxn",
            price3: "> $ 3000.00 mxn",
            fee1: "3.9%",
            fee2: "2.9%",
            fee3: "1.9%",
            ticketPrice: 0,
            quantity: 0
        }
    },
    computed: {
        currencyOptions() {
            return [...currencies.getSupportedCurrencies()]
        },
        fee() {
            if (isNaN(this.ticketPrice)) {
                return 0;
            }

            switch (this.selectedCurrency) {
                case 0: {
                    return amounts.getStandardFeeForMXN(this.ticketPrice)
                }
                case 1: {
                    return amounts.getStandardFeeForUSD(this.ticketPrice)
                }
                default:
                    return 0;
            }
        },
        priceWithFee() {

            if (isNaN(this.ticketPrice)) {
                return 0
            }

            var feePerTicket = this.ticketPrice * this.fee;
            return this.ticketPrice - feePerTicket
        },
        total() {
            if (isNaN(this.ticketPrice) || isNaN(this.quantity)) {
                return formatters.formatCurrency(0);
            }

            return formatters.formatCurrency(this.priceWithFee * this.quantity);
        },
        currencyTotal() {
            return this.selectedCurrency === 0 ? 'mxn' : 'usd'
        },
        formattedFee() {
            return formatters.formatPctFee(this.fee * 100) + ' %'
        },
        formattedPriceWithFee() {
            return formatters.formatCurrency(this.priceWithFee)
        },
        planName() {
            return this.fee === 0.039 ? this.$t('pricing.basic') : this.fee === 0.029 ? this.$t('pricing.standard') : this.$t('pricing.preference')
        }
    },
    watch: {
        selectedCurrency(newValue) {
            switch (newValue) {
                case 0: {
                    this.price1 = "< $ 999.99 mxn"
                    this.price2 = "$ 1000.00 - $ 2999.99 mxn"
                    this.price3 = "> $ 3000.00 mxn"
                    break;
                }
                case 1: {
                    this.price1 = "< $ 49.99 usd"
                    this.price2 = "$ 50.00 - $ 149.99 usd"
                    this.price3 = "> $ 150.00 usd"
                    break;
                }
                default:
                    break;
            }
        }
    }
}
</script>

<style scoped>
.purple-container {
    background-color: #652d66dd;
    padding: 20px;
}

.subtitle {
    text-align: center;
    margin: 40px;
}

.subtitle.light {
    color: white;
}

.pricing-tiles {
    text-align: center;
}

.currencies-dropdown {
    width: 320px;
}

.container {
    max-height: 300px;
    justify-content: center;
    align-items: center;
    background: white;
    padding: 20px;
}

.sim-container {
    max-width: 1450px;
    padding-top: 20px;
    margin: 0 auto;
}

.calculator-img {
    margin-top: 10px;
    margin-bottom: 40px;
}

.plan {
    color: #652d66;
    font-size: 2em;
    font-weight: 500;
}

.price {
    font-size: 2em;
}

.percentaje {
    color: #652d66;
    font-size: 4.2em;
    line-height: 0.2;
}

.operator {
    margin-left: 120px;
    font-size: 2em;
}

.total {
    font-size: 1.5em;
    font-weight: bolder;
    border-style: groove;
    border-width: 1px;
    color: #652d66;
    padding: 15px;
}

.superindex {
    vertical-align: super;
    font-size: 1.2em;
}

.stripe-container {
    background-color: rgb(84, 105, 212);
    padding: 20px;
    margin-top: 40px;
}
</style>