export default {
  "titles": {
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to Ticket Dancer for Business"])},
    "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter to Ticket Dancer for Business"])},
    "mainSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The app where you can manage your events in Ticket Dancer"])},
    "logEntry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage entries"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account preferences"])},
    "listOfEntries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of entries"])},
    "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports"])},
    "listOfPurchases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of purchases"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
    "myEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage my events"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
    "generalInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General information"])},
    "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images"])},
    "tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets"])},
    "otherPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other payments and registrations"])},
    "listOfBankTransfers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments by Bank Transfers"])},
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff access"])}
  },
  "subtitles": {
    "newEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new event in Ticket Dancer"])},
    "listOfRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check all the records of your Event"])},
    "entryReg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register the entrances of your event"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update your information"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome,"])},
    "listOfBankTransfers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review all payments made through bank transfers for your event"])},
    "stripeInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stripe account information"])},
    "stripePendingActions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have information pending completion in Stripe and your payouts may be blocked. Complete your information by clicking the button below"])},
    "stripePendingActionsAllPages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have information pending completion in Stripe and your payouts may be blocked. Complete your information in your Account Preferences"])}
  },
  "commonElements": {
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
    "entryType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of entry"])},
    "selectEntryType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select type of entry"])},
    "ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket"])},
    "tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets"])},
    "registry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment or registration"])},
    "registries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other payments and registries"])},
    "selectEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select event"])},
    "selectOtherPaymentRegistry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select type of payment or registry"])},
    "fixedPaymentInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* It must be mentioned in the Name of the payment to which ticket this payment corresponds (for instance, FULL PASS), and in the Description how many fixed payments are necessary to settle the full ticket (for instance, 3 payments)."])},
    "lodgingInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* It must be mentioned in the Name of the payment to which type of room it corresponds (for instance, QUADRUPLE ROOM). If it is a BUNDLE that includes both the TICKET to the Event and the ACCOMMODATION, it must be added as a TICKET instead of as Payment or Registration. Provide all additional details, notes, and conditions in the Record Description."])},
    "competitionInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* It must be mentioned in the Name of the payment to which type of registration it corresponds (for instance, SALSA PRO-AM). Provide all additional details, notes, and conditions in the Record Description."])},
    "initialPaymentInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* It must be mentioned in the Name of the payment to which ticket this initial payment corresponds (for instance, FULL PASS). Provide all additional details, notes and conditions in the Payment Description."])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
    "saveOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saver order"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "quantityAdjustment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantities adjustment"])},
    "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prices adjustment"])},
    "salePriceMXN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public sale price (MXN)"])},
    "feeMXN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee calculated (MXN)"])},
    "salePriceUSD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public sale price (USD)"])},
    "feeUSD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee calculated (USD)"])},
    "amountReceivedMXN": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Amount received per unit (MXN): ", _interpolate(_list(0)), " *"])},
    "amountReceivedUSD": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Amount received per unit (USD): ", _interpolate(_list(0)), " *"])},
    "netMXN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producer's net total"])},
    "feeNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fees automatically calculated"])},
    "mxnRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price range in Mexican pesos (MXN)"])},
    "usdRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price range in American dollars (USD)"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "noInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not available"])},
    "tbd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To be defined"])},
    "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results found"])},
    "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])},
    "important": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important!"])},
    "logoutConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You logged out successfully"])}
  },
  "filters": {
    "filterBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by"])},
    "searchBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search criteria"])},
    "orderNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order #"])},
    "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method"])},
    "cardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card digits"])},
    "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
    "ticketType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket type"])},
    "registerType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration type"])},
    "groupCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dance Group or Company"])},
    "coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount code"])},
    "tdUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket Dancer user email"])},
    "sn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["# Serial Number"])},
    "attendee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendee's name"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
    "genre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genre"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])}
  },
  "headers": {
    "paymentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment date (year-month-day)"])},
    "orderNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment order #"])},
    "ticketNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["# of tickets"])},
    "registerNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["# of registrations"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total amount"])},
    "netAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net amount received"])},
    "couponName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount code"])},
    "couponAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount code amount"])},
    "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question 1"])},
    "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question 2"])},
    "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question 3"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer 1"])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer 2"])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer 3"])},
    "purchaseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase date (year-month-day)"])},
    "log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log"])},
    "logDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log date (year-month-day)"])},
    "logType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log type"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "enteredBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entered by"])},
    "withMSI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FII enabled"])},
    "transferDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer date (year-month-day)"])},
    "bankTransferComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is total amount settled?"])},
    "depositedAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposited amount"])},
    "bankReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank reference"])}
  },
  "home": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your account"])},
    "slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket Dancer® is your ideal partner"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing"])},
    "quote1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I had never worked so comfortably at an event!"])},
    "quote2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ease of seeing how sales are going at any time allows me to make timely decisions"])},
    "subtitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start selling in three easy steps"])},
    "step1title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your account"])},
    "step1message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You only need a valid email or a Gmail or Apple ID account to begin"])},
    "step2title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your information"])},
    "step2message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set up your bank account in Stripe® where you will directly receive the money from your sales"])},
    "step3title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your event"])},
    "step3message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your event information and start selling it on our platform"])},
    "subtitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your experience as a Producer"])},
    "realTimeReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales and transaction reports in real time"])},
    "discountCodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount code generation"])},
    "customTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customized tickets to your event"])},
    "qrScan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access control by QR scanner"])},
    "competitions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration of competitions and other concepts"])},
    "diffusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diffusion of your event in our community"])},
    "paymentMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept cards, installments, wallets and more"])},
    "focus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You focus on your event and we focus on Customer Service"])},
    "subtitle3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are specialists in dance events!"])},
    "unique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unique"])},
    "uniqueMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no other specialized system in the management of a dance congress"])},
    "tested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tested"])},
    "testedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have been operating in the dance industry for years without setbacks. Our clients can vouch for it"])},
    "reliable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reliable"])},
    "reliableMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secure commercial transactions with the support of our ally Stripe®, with capacity for any event"])},
    "authentic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentic"])},
    "authenticMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designed by passionate dancers, producers and professionals specialized in their field with global experience"])},
    "userXP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user experience"])},
    "userXPMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have apps for iOS and Android, as well as a website so that users can purchase their tickets and registrations in the simplest and safest way, no matter where they access them from."])},
    "successStories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success stories"])},
    "uxp1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Safe and comfortable purchases from the Ticket Dancer App"])},
    "uxp2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go buy directly from a link on your social networks"])},
    "uxp3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ticket with QR code arrives to them instantly"])},
    "uxp4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All tickets are available in their Ticket Dancer account"])},
    "uxp5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convenience and easy access by presenting their tickets on their device"])},
    "uxp6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find the best events in one place from their device"])},
    "uxp7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchases in dollars (USD) or pesos (MXN), according to the user preference"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
    "faqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequently asked questions"])},
    "contactForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact form"])},
    "contactType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact type"])},
    "successContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have received your request and we will attend it soon"])},
    "createUserConfirmation1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to use the following email?"])},
    "createUserConfirmation2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot change your email later."])},
    "createUserNotification": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["An email from Ticket Dancer was sent to ", _interpolate(_list(0)), " to verify your account. Please check your inbox (or Spam) and follow the instructions."])},
    "guadalayorkSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guadalajara Jalisco awaits you year after year with this great event of diverse Latin rhythms"])},
    "guadalayorkDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For the third consecutive year on our platform, Guadalayark brings us more than 50 workshops with national and international artists, competitions, show and social nights and the traditional closing party in Tequila, Jalisco"])},
    "just4uSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An event dedicated to the growth of women using dance as a means of projection and growth."])},
    "just4uDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Its mission is to generate forums for the projection of women in the dance environment, as dancers, teachers, competitors, entrepreneurs, lovers of dance in all its expressions and all those with entrepreneurial ideas."])},
    "csbfSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Festival that is breaking with the ordinary, incredible artists from all over the world, gathered in one place, Cancun."])},
    "csbfDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enjoy the best vacations with artists that we bring to you in the paradise of the Riviera Maya, where the turquoise sea of ​​the Caribbean meets white sand beaches. Social and coexistence almost 24/7 non-stop for 5 days and 4 nights so that your moments are unforgettable."])},
    "ballareSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One of the best Salsa Socials in Mexico"])},
    "ballareDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is an event for lovers of salsa and bachata, both for the dancer who likes a good social dance, as well as for the professional who wants to train with the best dancers in the world and present their show. Our goal is to provide you with a great experience with a hotel as a venue, lots of uninterrupted social, the best selection of music, state-of-the-art audio and the best national and international dancers."])},
    "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join our Success Cases!"])}
  },
  "pricing": {
    "header1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We made it simple for you"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A service plan -commission percentage- is applied based on the ticket or registration sales price. This plan is applied individually to each ticket or registration."])},
    "basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic"])},
    "standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
    "preference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preference"])},
    "calulatorHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales simulator"])},
    "publicPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale price"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
    "feePerTicket": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Plan applied: ", _interpolate(_list(0)), " (", _interpolate(_list(1)), ")"])},
    "amountReceived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Amount received per unit: ", _interpolate(_list(0)), " ", _interpolate(_list(1))])},
    "totalReceived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Total received: ", _interpolate(_list(0)), " ", _interpolate(_list(1))])},
    "stripeDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* It does not include the fee by"])},
    "stripeDisclaimer2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For more information about Stripe™ benefits and fees, please visit the following"])},
    "ally": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Business ally - Stripe ™"])},
    "stripeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To ensure that online purchases through Ticket Dancer™ meet security and reliability requirements, we use the world-renowned tool called Stripe™."])},
    "stripeDescription2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks to our integration with Stripe you will be able to manage many international payment methods for your clients easily and safely, such as:"])}
  },
  "paymentMethods": {
    "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit and debit cards"])},
    "vouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vouchers"])},
    "wallets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-wallets"])},
    "transfers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank transfers"])},
    "installmentsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installents"])},
    "visa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa Inc (Visa) is a global digital payment technology company that serves individual and commercial clients, financial institutions, government entities, and merchants."])},
    "mastercard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MasterCard is a technology firm in the global electronic payment industry responsible for processing electronic payments, through its full range of payment programs and services."])},
    "amex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["American Express Co (Amex) is a provider of card products and travel-related services. It offers a range of credit cards, charge cards, and financing solutions to individuals, small and middle businesses, and corporates."])},
    "applepay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apple Pay provides an easy and secure way to make payments in your iOS, iPadOS, and watchOS apps, and on websites in Safari. And now, Apple Pay can also be used in Messages for Business and in iMessage extensions."])},
    "googlepay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Pay enables users to pay for transactions with Android devices in-store and on supported websites, mobile apps and Google services, like the Google Play Store."])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link autofills your customers' payment and address details so they can easily complete their purchases. Businesses with a large repeat customer base see an average returning user conversion increase of 14%."])},
    "oxxo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OXXO allows customers in Mexico to pay cash at their popular convenience stores."])},
    "bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popular for accepting large, one-off payments. The customer initiates the transfer from their bank's site, app, ATM, or in-person branch."])},
    "installments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It's a feature of some consumer credit cards in Mexico that allows customers to split purchases over multiple billing statements. You'll receive the full amount (minus a fee) as if it were a normal charge, and the customer's bank handles collecting the money."])}
  },
  "faqs": {
    "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where does the money from the tickets I have sold go?"])},
    "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can I change the bank account I initially registered?"])},
    "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How long does it take for the money to be reflected in the bank account I set up?"])},
    "q4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do I have to pay taxes?"])},
    "q5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It's my first event, could you advise me?"])},
    "q6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do I need to pay to start using the app?"])},
    "q7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How does access control work?"])},
    "q8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why should you use Ticket Dancer?"])},
    "q9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If I have any other questions, can I contact you?"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The money goes directly to the bank account you set up when creating your account on the Ticket Dancer platform."])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, you can change it at any time as many times as you want."])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payouts can be daily, weekly, biweekly, or monthly, as required."])},
    "a4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, remember that all income must be declared in accordance with the applicable laws."])},
    "a5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Of course, we have a consulting service in strategic planning, budget, taxes, social networks, content creation, communication, among others."])},
    "a6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, your success is ours, we only charge commission per ticket sold."])},
    "a7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket Dancer has an exclusive section for your staff, the user appears at the registration table with their electronic ticket with barcode, the assigned staff staff scans the barcode and marks the ticket as entered, the staff staff only You can perform this function and the username is recorded for any clarification."])},
    "a8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are many reasons, but we consider that the main one is that you will have automated sales management and access control, you will not need to write anything at all, and since it is automatic you will not have to invest more time in following up on a payment, confirming it, and recording it. The risk of human error or fraud is mitigated, so your team can focus on other more strategic areas such as direct sales and customer service."])},
    "a9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Of course, our contact is support", "@", "ticketdancer.com.mx, or fill out the form below this section"])}
  },
  "contactForm": {
    "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name (optional)"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])}
  },
  "contactCategories": {
    "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales"])},
    "consulting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulting and Support"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])}
  },
  "myEvents": {
    "dateField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of the event (once published it cannot be edited):"])},
    "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date:"])},
    "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date:"])},
    "linkField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to the event on Ticket Dancer. Only letters, numbers and middle dashes are allowed. Accents and punctuation marks are not allowed. (Example: my-great-event-2023). Once the Event is created it cannot be modified"])},
    "eventName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event name"])},
    "youtubeLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YouTube link"])},
    "eventDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event description (SPANISH)"])},
    "eventDescriptionEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event description (ENGLISH)"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
    "locationField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a location"])},
    "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Published"])},
    "notPublished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOT published"])},
    "mainImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main image (Recommended size: 800px * 500px)"])},
    "bannerImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banner of the event (Recommended size: 800px * 400px)"])},
    "logoImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producer or Event Logo (Recommended size: 500px * 400px)"])},
    "successImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The image has been successfully updated"])},
    "galleryImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gallery images (up to 20 images)"])},
    "createEventConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to create the Event?"])},
    "createEventDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep in mind that there will be fields that you will no longer be able to modify later"])},
    "successCreateEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The event has been successfully created"])},
    "successUpdateEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The event has been successfully updated"])},
    "deleteEventConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete this Event?"])},
    "deleteEventDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Event information will be lost and you will no longer be able to recover it."])},
    "successDeleteEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The event has been successfully deleted"])},
    "publishConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to publish your Event?"])},
    "publishDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket Dancer will validate the information and notify you once the Event is published"])},
    "successPublish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The publication of your Event has been requested. We will keep you informed"])},
    "enableMSI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable Free-Interests Installments in all the event (applies to all Tickets and Other Payments and Registrations)"])}
  },
  "tickets": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new ticket"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket name"])},
    "maxLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max limit of tickets"])},
    "userTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket limit per user"])},
    "attendeesPerTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["# of attendees per ticket"])},
    "mandatoryName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandatory name"])},
    "ticketsPurchased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets purchased"])},
    "mandatoryNameForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is attendee's name mandatory?"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active ticket"])},
    "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive ticket"])},
    "ticketDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket description (SPANISH)"])},
    "ticketDescriptionEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket description (ENGLISH)"])},
    "editDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update your ticket information (some fields may be disabled for security reasons)"])},
    "purchasedDeletionError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The ticket '", _interpolate(_list(0)), "' can't be deleted because it already has purchases registered"])},
    "confirmDelete": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Do you really want to DELETE the ticket '", _interpolate(_list(0)), "'?"])},
    "deleteConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ticket has been successfully deleted"])},
    "confirmDisable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Do you really want to DISABLE the ticket '", _interpolate(_list(0)), "'?"])},
    "disableDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ticket will NOT be shown as an option for users"])},
    "disableConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ticket was successfully disabled. It will no longer be shown to users"])},
    "confirmEnable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Do you really want to ENABLE the ticket '", _interpolate(_list(0)), "'?"])},
    "enableDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ticket WILL be shown as an option for users"])},
    "enableConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ticket was successfully enabled. It will now be shown to users"])},
    "duplicateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate a ticket for your event"])},
    "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new ticket for your event"])},
    "addConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The new ticket has been successfully created"])},
    "updateConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ticket has been successfully updated"])},
    "enableMSI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable Free-Interests Installments for this ticket"])}
  },
  "otherPayments": {
    "fixedPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FIXED PAYMENT"])},
    "accommodation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACCOMMODATION"])},
    "competition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMPETITION"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTHER"])},
    "createNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crate new payment or registration"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the payment or registration"])},
    "question1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question 1 (optional)"])},
    "question2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question 2 (optional)"])},
    "question3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question 3 (optional)"])},
    "maxLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max limit of payments or registrations"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of payment or registry"])},
    "optionalQuestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional questions"])},
    "registries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments or registrations purchased"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active payment or registration"])},
    "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive payment or registration"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description of payment or registration (SPANISH)"])},
    "descriptionEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description of payment or registration (ENGLISH)"])},
    "editDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update your payment information (some fields may be disabled for security reasons)"])},
    "duplicateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate a payment or registration for your event"])},
    "confirmDisable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Do you really want to DISABLE the payment '", _interpolate(_list(0)), "'?"])},
    "disableDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment or registration will NOT be shown as an option for users"])},
    "disableConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment or registration was successfully disabled. It will no longer be shown to users"])},
    "confirmEnable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Do you really want to ENABLE the payment or registration '", _interpolate(_list(0)), "'?"])},
    "enableDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment WILL be shown as an option for users"])},
    "enableConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment or registration was successfully enabled. It will now be shown to users"])},
    "purchasedDeletionError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The payment '", _interpolate(_list(0)), "' can't be deleted because it already has purchases registered"])},
    "confirmDelete": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Do you really want to DELETE the payment '", _interpolate(_list(0)), "'?"])},
    "deleteConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment or registration has been successfully deleted"])},
    "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new payment or registration for your event"])},
    "addConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The new payment or registration has been successfully created"])},
    "updateConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment or registration has been successfully updated"])},
    "enableMSI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable Free-Interests Installments for this payment"])}
  },
  "staff": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new Staff account"])},
    "userId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active staff"])},
    "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive staff"])},
    "confirmDelete": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Do you really want to DELETE the user: '", _interpolate(_list(0)), "'?"])},
    "deleteConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user has been successfully deleted"])},
    "confirmDisable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Do you really want to DISABLE the user: '", _interpolate(_list(0)), "'?"])},
    "disableDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user will NOT have access to perform any entry registration of your event"])},
    "disableConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user was successfully disabled"])},
    "confirmEnable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Do you really want to ENABLE the user: '", _interpolate(_list(0)), "'?"])},
    "enableDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user WILL have access to perform entry registration of your event"])},
    "enableConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user was successfully enabled"])},
    "createdTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New staff account created"])},
    "createdDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save and share this password accordingly. After you close this Window you won't be able to recover it."])},
    "updateConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user has been successfully updated"])},
    "editTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Edit '", _interpolate(_list(0)), "' information"])}
  },
  "reports": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General report of your events"])},
    "otherPaymentsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report of other Payments and Registrations"])},
    "ticketsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket report"])},
    "filterEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by event"])},
    "filterRegistry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by payment or registration"])},
    "filterTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by ticket"])},
    "eventName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event name"])},
    "ticketTypeQty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket type: Quantity"])},
    "registrationTypeQty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment or registration type: Quantity"])}
  },
  "purchases": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review all purchases made for your event"])},
    "ticketsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of completed ticket purchases"])},
    "registriesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of other Payments and Registrations completed"])},
    "stripeLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to your Stripe account dashboard"])},
    "card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card"])},
    "oxxo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OXXO (Convenience store in Mexico)"])},
    "bankTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Transfer"])},
    "listOfTicketsPurchased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of purchased tickets"])},
    "listOfOtherPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of other purchased registrations"])},
    "hasEntered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has entered?"])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])}
  },
  "entryReg": {
    "logTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log history"])},
    "entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENTRANCE"])},
    "cancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CANCEL ENTRANCE"])},
    "entryDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry to the event is registered"])},
    "registerEntranceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the Ticket or Registration data"])},
    "typeEntrySelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select type of entry:"])},
    "searchSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search entry by:"])},
    "qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR Code"])},
    "searchResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search results"])},
    "entered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* MARKED AS ENTERED"])},
    "notEntered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* NOT MARKED"])},
    "entranceDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Carefully validate all data before proceeding"])},
    "cancelEntranceConfirm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Cancel the entrance of ", _interpolate(_list(0)), ": ", _interpolate(_list(1)), "?"])},
    "cancelEntranceDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describe the reason for Cancellation"])},
    "reader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR reader connected"])},
    "camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device camera"])},
    "selectInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select QR code input reader"])},
    "loadingCamera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading camera..."])}
  },
  "user": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in using account of"])},
    "producer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producer"])},
    "staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "rights": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["All rights reserved, Ticket Dancer™ ", _interpolate(_list(0))])},
    "names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name(s)"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "passwordUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update your password"])},
    "currentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current password"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
    "confirmNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm new password"])},
    "updateConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your information has been successfully updated"])},
    "existing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I already have an account"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new account"])},
    "createDisclosure1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I declare having read and accepted our "])},
    "createDisclosure2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of use (Spanish)"])}
  },
  "actions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])},
    "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide"])},
    "moveDown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move down"])},
    "moveUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move up"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "createEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new event"])},
    "requestPublish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request to publish"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "markEntrance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark entrance"])},
    "cancelEntrance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel entrance"])},
    "confirmCancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm cancellation"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create account"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "forgoPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot my password"])},
    "browse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open in a new window"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied!"])}
  },
  "forgoPassword": {
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password?"])},
    "instructions1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We'll send you a reset code to your email. Enter the email you used to register to Ticket Dancer for Business."])},
    "instructions1button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send code"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have my reset code"])},
    "instructions2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email in the text field above and the code you received in your inbox here."])},
    "instructions2TF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code (single use)"])},
    "instructions2button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate code"])},
    "codeSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have sent you the access code to your email. In a phew moments you'll receive it in your inbox."])}
  },
  "validations": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is required"])},
    "fieldRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required"])},
    "greaterThanZero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["must be greater than 0"])},
    "tooBig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" is too big"])},
    "lessThan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" must be less than "])},
    "cannotUndo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action cannot be undone"])},
    "imageSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The image size must not be larger than 3 MB"])},
    "eventLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Event Link is invalid. Only letters, numbers and dashes are allowed. Neither accents nor spaces are allowed"])},
    "allDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Both event dates are mandatory (Start and End date)"])},
    "endDateGreaterThanStartDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check that the Event End date occurs after the Event Start date"])},
    "endDatePast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check that the Event End date does not occur in the past"])},
    "eventNameMandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The event name is mandatory"])},
    "eventDescriptionMandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The event description is mandatory"])},
    "eventDescriptionLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The event description (SPANISH) must be less than 4000 characteres"])},
    "eventDescriptionLimitEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The event description (ENGLISH) must be less than 4000 characteres"])},
    "eventLocationMandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The event location is mandatory"])},
    "passwordRequirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" must be at least 6 characters long, one uppercase letter, one lowercase letter, and one number"])},
    "passwordMismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password confirm failed"])},
    "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email"])},
    "invalidPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password must be at least 6 characters long, one capital letter, one lower capital letter and a number."])},
    "mismatchPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The passwords don't match"])}
  },
  "currencies": {
    "mxn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🇲🇽 - Mexican pesos"])},
    "usd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🇺🇸 - US dollars"])}
  },
  "footer": {
    "aboutTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "aboutText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ticket Dancer® and Ticket Dancer for Business are an integral platform that contain all the information about dance events, giving the promotors a chance to sell, distribute and spread their tickets, as well as rent advertising spaces within our app netowrk.\nTicket Dancer®, is the best option for dance events promotors, as well as the most secure and reliable choice for people who enjoy dance events.\n\nVersion: ", _interpolate(_list(0)), "\n\nLicenses: The icons used in this app were brought to you by Icons8 under Creative Commons Attribution-NoDerivs 3.0 Unported license. https://creativecommons.org/licenses/by-nd/3.0/\nhttps://iconos8.es"])},
    "linksTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links"])},
    "linksMission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mission & Vision (Spanish)"])},
    "linksPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy (Spanish)"])},
    "linksTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms & conditions (Spanish)"])},
    "linksFaqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQS"])},
    "contactTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RIGHTS RESERVED FOR TICKET DANCER ®"])}
  },
  "errors": {
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops! Page not found :("])},
    "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong. Try again later"])},
    "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error ocurred while uploading the image"])}
  }
}