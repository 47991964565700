<template>
    <div class="staff-container">
        <ui-skeleton :loading="loadingStaff" :title="false" active :paragraph="{ rows: 30 }">
            <div class="create-staff">
                <ui-button raised @click="createStaff"> {{ $t('staff.create') }}
                    <template #after="{ iconClass }">
                        <ui-icon :class="iconClass">add</ui-icon>
                    </template>
                </ui-button>
            </div>
            <error-component v-show="errorStaff" :message="errorMessage" />
            <h3 class="subtitle">{{ $t('commonElements.active') }}</h3>
            <ui-table fullwidth :data="staffActivos" :thead="theadStaff" :tbody="tbodyStaff" selected-key="userId">
                <template #actions="{ data }">
                    <ui-button icon="edit" @click="editStaff(data)"> {{ $t('actions.edit') }} </ui-button>
                    <ui-button icon="visibility_off" @click="disableStaff(data)"> {{ $t('actions.disable') }}
                    </ui-button>
                    <ui-button icon="delete" @click="deleteStaff(data)"> {{ $t('actions.delete') }} </ui-button>
                </template>
            </ui-table>
            <ui-divider></ui-divider>
            <h3 class="subtitle">{{ $t('commonElements.inactive') }}</h3>
            <ui-table fullwidth :data="staffInactivos" :thead="theadStaff" :tbody="tbodyStaff" selected-key="userId">
                <template #actions="{ data }">
                    <ui-button icon="edit" @click="editStaff(data)"> {{ $t('actions.edit') }} </ui-button>
                    <ui-button icon="visibility" @click="enableStaff(data)"> {{ $t('actions.enable') }}
                    </ui-button>
                    <ui-button icon="delete" @click="deleteStaff(data)"> {{ $t('actions.delete') }} </ui-button>
                </template>
            </ui-table>
            <error-component v-show="errorStaff" :message="errorMessage" />
        </ui-skeleton>

        <!-- New User dialog -->

        <ui-dialog v-model="openNewUserDialog" :closable="false">
            <ui-dialog-title> {{ $t('staff.createdTitle') }}</ui-dialog-title>
            <ui-dialog-content style="padding: 20px;">
                <ui-form item-margin-bottom="20" type="|" action-align="right">
                    <template #default="{ subitemClass, actionClass }">
                        <ui-form-field :class="subitemClass">
                            <ui-textfield outlined v-model="form.nombre" required helper-text-id="names-helper-text">
                                {{ $t('user.names') }}
                            </ui-textfield>
                            <ui-textfield-helper id="names-helper-text"
                                v-model:validMsg="validMsg.nombre"></ui-textfield-helper>
                        </ui-form-field>
                        <ui-form-field :class="subitemClass">
                            <ui-textfield outlined v-model="form.apellidos" required
                                helper-text-id="lastname-helper-text">
                                {{ $t('user.lastname') }}
                            </ui-textfield>
                            <ui-textfield-helper id="lastname-helper-text"
                                v-model:validMsg="validMsg.apellidos"></ui-textfield-helper>
                        </ui-form-field>
                        <ui-form-field :class="actionClass">
                            <ui-button outlined @click="saveStaff"> {{ $t('actions.save') }} </ui-button>
                        </ui-form-field>
                    </template>
                </ui-form>
                <ui-progress indeterminate v-show="loadingStaff"></ui-progress>
                <error-component v-show="errorStaff" :message="errorMessage" />
                <ui-divider style="margin-bottom: 20px;"></ui-divider>
                <ui-form item-margin-bottom="20" type="|" action-align="right">
                    <template #default="{ subitemClass, actionClass }">
                        <ui-form-field :class="subitemClass">
                            <ui-textfield outlined v-model="newUserId.text" :attrs="{ readonly: true }">
                                {{ $t('staff.userId') }}
                                <template #after>
                                    <ui-textfield-icon v-copy="newUserId">content_copy</ui-textfield-icon>
                                </template>
                            </ui-textfield>
                        </ui-form-field>
                        <ui-form-field :class="subitemClass">
                            <ui-textfield outlined v-model="newPassword.text" :attrs="{ readonly: true }">
                                {{ $t('user.password') }}
                                <template #after>
                                    <ui-textfield-icon v-copy="newPassword">content_copy</ui-textfield-icon>
                                </template>
                            </ui-textfield>
                        </ui-form-field>
                        <ui-form-field :class="subitemClass">
                            <label><span style="font-weight: bold; font-size: large;"> {{ $t('commonElements.important')
                                    }}</span>
                                {{
                                    $t('staff.createdDisclaimer') }}</label>
                        </ui-form-field>
                        <ui-form-field :class="actionClass">
                            <ui-button outlined @click="closeNewStaffDialog"> OK </ui-button>
                        </ui-form-field>
                    </template>
                </ui-form>
            </ui-dialog-content>
        </ui-dialog>

        <!-- Existing User dialog -->

        <ui-dialog v-model="openEditUserDialog">
            <ui-dialog-title> {{ $t('staff.editTitle', [form.userId]) }}</ui-dialog-title>
            <ui-dialog-content style="padding: 20px;">
                <ui-form item-margin-bottom="20" type="|" action-align="right">
                    <template #default="{ subitemClass }">
                        <ui-form-field :class="subitemClass">
                            <label class="active-text" :class="activeClass">{{ activeText }}</label>
                        </ui-form-field>
                        <ui-form-field :class="subitemClass">
                            <ui-textfield outlined v-model="form.nombre" required helper-text-id="names-helper-text">
                                {{ $t('user.names') }}
                            </ui-textfield>
                            <ui-textfield-helper id="names-helper-text"
                                v-model:validMsg="validMsg.nombre"></ui-textfield-helper>
                        </ui-form-field>
                        <ui-form-field :class="subitemClass">
                            <ui-textfield outlined v-model="form.apellidos" required
                                helper-text-id="lastname-helper-text">
                                {{ $t('user.lastname') }}
                            </ui-textfield>
                            <ui-textfield-helper id="lastname-helper-text"
                                v-model:validMsg="validMsg.apellidos"></ui-textfield-helper>
                        </ui-form-field>
                    </template>
                </ui-form>
            </ui-dialog-content>
            <ui-dialog-actions>
                <ui-button class="action-button" :disabled="loadingStaff" @click.prevent="openEditUserDialog = false">
                    {{
                        $t('actions.cancel') }}</ui-button>
                <ui-button class="action-button" raised :disabled="loadingStaff" @click.prevent="saveStaff"> {{
                    $t('actions.save') }}</ui-button>
            </ui-dialog-actions>
        </ui-dialog>
    </div>
</template>

<script>
import { useMainStore } from '@/stores/main';
import { mapState, mapActions } from "pinia";
import { helpers, useValidator } from "balm-ui";
import i18n from '@/i18n';
import ErrorComponent from "@/components/common/ErrorComponent.vue";

const theadStaff = [
    i18n.global.t('staff.userId'),
    i18n.global.t('user.names'),
    i18n.global.t('user.lastname'),
    { value: i18n.global.t('actions.title'), align: "center" },
]

const tbodyStaff = [
    "userId",
    "nombre",
    "apellidos",
    {
        slot: "actions",
    },
]

const validations = [
    {
        key: "nombre",
        label: i18n.global.t('user.names'),
        validator: "required",
    },
    {
        key: "apellidos",
        label: i18n.global.t('user.lastname'),
        validator: "required",
    }
]

export default {
    components: {
        ErrorComponent
    },
    computed: {
        ...mapState(useMainStore, ['writableEvent']),
        staffActivos() {
            return helpers.isEmpty(this.writableEvent.staffList) ? [] : [...this.writableEvent.staffList.filter(x => x.activo)]
        },
        staffInactivos() {
            return helpers.isEmpty(this.writableEvent.staffList) ? [] : [...this.writableEvent.staffList.filter(x => !x.activo)]
        },
        activeText() {
            return this.staffActive ? this.$t('staff.active') : this.$t('staff.inactive')
        },
        activeClass() {
            return this.staffActive ? 'active-color' : 'inactive-color'
        },
    },
    data() {
        return {
            theadStaff: theadStaff,
            tbodyStaff: tbodyStaff,
            staffActive: false,
            selectedUserId: "",
            openNewUserDialog: false,
            openEditUserDialog: false,
            balmUI: useValidator(),
            validations,
            validMsg: {},
            form: {
                userId: "",
                nombre: "",
                apellidos: "",
            },
            newUserId: {
                text: "",
                success: () => {
                    this.$toast(this.$t('actions.copied'));
                }
            },
            newPassword: {
                text: "",
                success: () => {
                    this.$toast(this.$t('actions.copied'));
                }
            },
            loadingStaff: false,
            errorMessage: "",
            errorStaff: false
        }
    },
    methods: {
        ...mapActions(useMainStore, ['addStaff', 'updateStaff', 'activateStaff', 'deactivateStaff', 'removeStaff']),
        async createStaff() {

            try {
                this.loadingStaff = true;
                const createdStaff = await this.addStaff({
                    events: [{ id: this.writableEvent.id }]
                })
                //console.log(createdStaff)
                this.loadingStaff = false;
                this.newUserId.text = createdStaff.userId
                this.newPassword.text = createdStaff.password
                this.selectedUserId = createdStaff.userId
                this.form.userId = createdStaff.userId
                this.form.nombre = createdStaff.nombre
                this.form.apellidos = createdStaff.apellidos
                this.openNewUserDialog = true;

            } catch (error) {
                this.loadingStaff = false
                this.errorStaff = true
                this.errorMessage = error
            }

        },
        closeNewStaffDialog() {
            this.openNewUserDialog = false;
        },
        editStaff(staffSelected) {
            //console.log(ticketSelected)
            this.staffActive = staffSelected.activo
            this.selectedUserId = staffSelected.userId
            this.form.userId = staffSelected.userId
            this.form.nombre = staffSelected.nombre
            this.form.apellidos = staffSelected.apellidos
            this.openEditUserDialog = true;
        },
        deleteStaff(staffSelected) {
            this.$confirm({
                title: this.$t('staff.confirmDelete', [staffSelected.userId]),
                message: this.$t('validations.cannotUndo'),
                state: 'warning',
                stateOutlined: true,
                acceptText: this.$t('actions.delete'),
                cancelText: this.$t('actions.cancel'),
            }).then(async (result) => {

                if (result) {
                    this.loadingStaff = true
                    this.errorStaff = false
                    this.errorMessage = ""

                    try {

                        await this.removeStaff({
                            events: [{ id: this.writableEvent.id }],
                            userId: staffSelected.userId
                        })
                        this.$toast(this.$t('staff.deleteConfirmation'));
                        this.loadingStaff = false

                    } catch (error) {
                        this.loadingStaff = false
                        this.errorStaff = true
                        this.errorMessage = error
                    }
                }
            })
        },
        disableStaff(staffSelected) {
            this.$confirm({
                title: this.$t('staff.confirmDisable', [staffSelected.userId]),
                message: this.$t('staff.disableDisclaimer'),
                state: 'help',
                stateOutlined: true,
                acceptText: this.$t('actions.disable'),
                cancelText: this.$t('actions.cancel'),
            }).then(async (result) => {

                if (result) {

                    this.loadingStaff = true
                    this.errorStaff = false
                    this.errorMessage = ""

                    try {

                        await this.deactivateStaff({
                            events: [{ id: this.writableEvent.id }],
                            userId: staffSelected.userId
                        })
                        this.$toast(this.$t('tickets.disableConfirmation'));
                        this.loadingStaff = false

                    } catch (error) {
                        this.loadingStaff = false
                        this.errorStaff = true
                        this.errorMessage = error
                    }
                }
            })
        },
        enableStaff(staffSelected) {
            this.$confirm({
                title: this.$t('staff.confirmEnable', [staffSelected.userId]),
                message: this.$t('staff.enableDisclaimer'),
                state: 'help',
                stateOutlined: true,
                acceptText: this.$t('actions.enable'),
                cancelText: this.$t('actions.cancel'),
            }).then(async (result) => {

                if (result) {

                    this.loadingStaff = true
                    this.errorStaff = false
                    this.errorMessage = ""

                    try {

                        await this.activateStaff({
                            events: [{ id: this.writableEvent.id }],
                            userId: staffSelected.userId
                        })
                        this.$toast(this.$t('tickets.enableConfirmation'));
                        this.loadingStaff = false

                    } catch (error) {
                        this.loadingStaff = false
                        this.errorStaff = true
                        this.errorMessage = error
                    }
                }
            })
        },
        async saveStaff() {
            let result = this.balmUI.validate(this.form);
            let { valid, validMsg } = result;
            this.validMsg = validMsg;

            //console.log(valid)
            //console.log(validMsg)

            if (valid) {

                try {

                    this.loadingStaff = true
                    this.openEditUserDialog = false
                    this.errorStaff = false
                    this.errorMessage = ""

                    await this.updateStaff({
                        events: [{ id: this.writableEvent.id }],
                        userId: this.form.userId,
                        nombre: this.form.nombre,
                        apellidos: this.form.apellidos
                    })

                    this.$toast(this.$t('staff.updateConfirmation'));
                    this.loadingStaff = false

                } catch (error) {
                    this.loadingStaff = false
                    this.errorStaff = true
                    this.errorMessage = error
                }
            }
        }
    }
}
</script>

<style scoped>
.staff-container {
    margin-top: 40px;
}

.add-staff {
    text-align: left;
    margin-bottom: 20px;
}

.subtitle {
    text-align: center;
}

.create-staff {
    text-align: left;
    margin-bottom: 20px;
}

.active-text {
    margin-top: 20px;
    margin-bottom: 10px;
    width: 100%;
    text-align: right;
    font-weight: bold;
    font-size: large;
}

.active-color {
    color: #006600;
}

.inactive-color {
    color: gray;
}


.action-button {
    margin-left: 20px;
}
</style>