import { helpers } from 'balm-ui'; // Default Usage
import i18n from '@/i18n';
// OR
// import helpers from 'balm-ui/utils/helpers'; // ### Individual Usage

export default {
  required: {
    validate(value) {
      return !helpers.isEmpty(value);
    },
    message: '%s ' + i18n.global.t('validations.required')
  },
  positive: {
    validate(value) {
      const number = Number(value)
      return !isNaN(number) && number > 0
    },
    message: '%s ' + i18n.global.t('validations.greaterThanZero')
  },
  validEmail: {
    validate(value) {
      return /[A-Z0-9a-z]([A-Z0-9a-z._%+-]{0,30}[A-Z0-9a-z])?@([A-Z0-9a-z]([A-Z0-9a-z-]{0,30}[A-Z0-9a-z])?\.){1,5}[A-Za-z]{2,8}/.test(value)
    },
    message: i18n.global.t('validations.invalidEmail')
  },
  validPassword: {
    validate(value) {
      return /((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20})/.test(value)
    },
    message: i18n.global.t('validations.invalidPassword')
  },
  confirmPassword: {
    validate(value, data) {
      if (value !== data.password) return false;
      return true;
    },
    message: i18n.global.t('validations.mismatchPassword'),

  }
}
