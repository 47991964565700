<template>
    <div style="margin-top: 40px;">
        <div class=filters-container>
            <ui-chips v-model="selectedFilter" type="filter">
                <ui-chip v-for="(item, index) in paymentMethodOptions" :key="index" :icon="paymentMethodIcons[index]"
                    :hidden="selectedFilter.includes(index)">
                    {{ item }}
                </ui-chip>
            </ui-chips>
        </div>
        <ui-grid class="methods-container">
            <ui-grid-cell columns="4" v-if="selectedFilter.includes(0) || selectedFilter.length === 0">
                <td-tile class="pm-container" title="Visa" :message="visa" :img-url="require('@/assets/pm/visa.png')"
                    :img-height="64" v-shadow.transition="[2, 16]"></td-tile>
            </ui-grid-cell>
            <ui-grid-cell columns="4" v-if="selectedFilter.includes(0) || selectedFilter.length === 0">
                <td-tile class="pm-container" title="Mastercard" :message="mastercard"
                    :img-url="require('@/assets/pm/mastercard.png')" :img-height="64"
                    v-shadow.transition="[2, 16]"></td-tile>
            </ui-grid-cell>
            <ui-grid-cell columns="4" v-if="selectedFilter.includes(0) || selectedFilter.length === 0">
                <td-tile class="pm-container" title="American Express" :message="amex"
                    :img-url="require('@/assets/pm/amex.png')" :img-height="64" v-shadow.transition="[2, 16]"></td-tile>
            </ui-grid-cell>
            <ui-grid-cell columns="4" v-if="selectedFilter.includes(0) || selectedFilter.length === 0">
                <td-tile class="pm-container" :title="installmentsTitle" :message="installments"
                    :img-url="require('@/assets/pm/installments.png')" :img-height="64"
                    v-shadow.transition="[2, 16]"></td-tile>
            </ui-grid-cell>
            <ui-grid-cell columns="4" v-if="selectedFilter.includes(1) || selectedFilter.length === 0">
                <td-tile class="pm-container" title="Apple Pay" :message="applepay"
                    :img-url="require('@/assets/pm/applepay.png')" :img-height="64"
                    v-shadow.transition="[2, 16]"></td-tile>
            </ui-grid-cell>
            <ui-grid-cell columns="4" v-if="selectedFilter.includes(1) || selectedFilter.length === 0">
                <td-tile class="pm-container" title="Google Pay" :message="googlepay"
                    :img-url="require('@/assets/pm/googlepay.png')" :img-height="64"
                    v-shadow.transition="[2, 16]"></td-tile>
            </ui-grid-cell>
            <ui-grid-cell columns="4" v-if="selectedFilter.includes(1) || selectedFilter.length === 0">
                <td-tile class="pm-container" title="Link" :message="link" :img-url="require('@/assets/pm/link.png')"
                    :img-height="64" v-shadow.transition="[2, 16]"></td-tile>
            </ui-grid-cell>
            <ui-grid-cell columns="4" v-if="selectedFilter.includes(2) || selectedFilter.length === 0">
                <td-tile class="pm-container" title="OXXO" :message="oxxo" :img-url="require('@/assets/pm/oxxo.png')"
                    :img-height="64" v-shadow.transition="[2, 16]"></td-tile>
            </ui-grid-cell>
            <ui-grid-cell columns="4" v-if="selectedFilter.includes(2) || selectedFilter.length === 0">
                <td-tile class="pm-container" :title="bankTransfers" :message="bank"
                    :img-url="require('@/assets/pm/bank.png')" :img-height="64" v-shadow.transition="[2, 16]"></td-tile>
            </ui-grid-cell>
        </ui-grid>
    </div>
</template>

<script>

import TdTile from '@/components/home/TdTile.vue';
export default {
    name: "PaymentMethods",
    components: { TdTile },
    data() {
        return {
            selectedFilter: []
        }
    },
    computed: {
        paymentMethodOptions() {
            return [this.$t('paymentMethods.cards'), this.$t('paymentMethods.wallets'), this.$t('paymentMethods.vouchers')]
        },
        paymentMethodIcons() {
            return ['payment', 'account_balance_wallet', 'account_balance']
        },
        bankTransfers() {
            return this.$t('paymentMethods.transfers')
        },
        visa() {
            return this.$t('paymentMethods.visa')
        },
        mastercard() {
            return this.$t('paymentMethods.mastercard')
        },
        amex() {
            return this.$t('paymentMethods.amex')
        },
        applepay() {
            return this.$t('paymentMethods.applepay')
        },
        googlepay() {
            return this.$t('paymentMethods.googlepay')
        },
        link() {
            return this.$t('paymentMethods.link')
        },
        oxxo() {
            return this.$t('paymentMethods.oxxo')
        },
        bank() {
            return this.$t('paymentMethods.bank')
        },
        installmentsTitle() {
            return this.$t('paymentMethods.installmentsTitle')
        },
        installments() {
            return this.$t('paymentMethods.installments')
        }
    }
}
</script>

<style scoped>
.filters-container {
    max-width: 610px;
    margin: 0 auto;
    align-items: center;
    display: flex;
}

.methods-container {
    max-width: 1280px;
    margin: 0 auto;
}

.pm-container {
    background: white;
    padding: 10px;
    border-radius: 8px;
    height: 350px;
}
</style>