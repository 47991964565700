<template>
    <div class="images-container">
        <image-uploader :img-width="800" :img-height="500" :img-title="this.$t('myEvents.mainImage')"
            :img-url="principalImage" img-type="EVENT_PRINCIPAL"></image-uploader>
        <ui-divider></ui-divider>
        <image-uploader :img-width="800" :img-height="400" :img-title="this.$t('myEvents.bannerImage')"
            :img-url="bannerImage" img-type="EVENT_BANNER"></image-uploader>
        <ui-divider></ui-divider>
        <image-uploader :img-width="500" :img-height="400" :img-title="this.$t('myEvents.logoImage')"
            :img-url="logoImage" img-type="EVENT_LOGO"></image-uploader>
    </div>
    <ui-divider></ui-divider>
    <h2 style="text-align: center;"> {{ $t('myEvents.galleryImages') }}</h2>
    <ui-grid class="gallery-container">
        <ui-grid-cell columns="4" v-for="(galleryImageUrl, index) in galleryImages" :key="index">
            <image-uploader :img-url="galleryImageUrl" img-type="EVENT_GALLERY"
                @on-image-deleted="loadGalleryImages"></image-uploader>
        </ui-grid-cell>
        <ui-grid-cell align="middle" v-if="!galleryFull">
            <ui-file accept="image/*" @change="onFilesChanged">
                <ui-button raised> {{ $t('actions.add') }}
                    <template #after="{ iconClass }">
                        <ui-icon :class="iconClass">add</ui-icon>
                    </template>
                </ui-button>
            </ui-file>
        </ui-grid-cell>
    </ui-grid>
    <ui-progress v-show="imageUploading" indeterminate active></ui-progress>
    <error-component v-show="!imageUploading && errorImages" :message="errorMessage" />
</template>

<script>
import ImageUploader from '@/components/common/ImageUploader.vue';
import { useMainStore } from '@/stores/main';
import { mapState } from 'pinia';
import EventsAPI from '@/api/EventsAPI';
import ErrorComponent from "@/components/common/ErrorComponent.vue";

export default {
    components: {
        ImageUploader,
        ErrorComponent
    },
    async mounted() {
        await this.loadGalleryImages();
    },
    data() {
        return {
            galleryImages: [],
            imageUploading: false,
            errorMessage: "",
            errorImages: false
        }
    },
    computed: {
        ...mapState(useMainStore, ['writableEvent', 'userLogged', 'selectedEventId']),
        principalImage() {
            return this.writableEvent ? this.writableEvent.urlPrincipalImage : ''
        },
        bannerImage() {
            return this.writableEvent ? this.writableEvent.urlBanner : ''
        },
        logoImage() {
            return this.writableEvent ? this.writableEvent.urlLogo : ''
        },
        galleryFull() {
            return this.galleryImages.length >= 20
        }
    },
    methods: {
        async loadGalleryImages() {
            try {

                if (this.userLogged && this.selectedEventId) {

                    const eventsAPI = new EventsAPI();

                    this.errorImages = false;
                    this.imageUploading = true;

                    this.galleryImages = await eventsAPI.listGalleryImages(this.userLogged.token, this.selectedEventId)
                    //console.log(this.galleryImages)
                    this.imageUploading = false;
                }

            } catch (error) {

                this.imageUploading = false;
                this.errorMessage = error;
                this.errorImages = true;
            }
        },
        async onFilesChanged(files) {
            this.files = [...files]
            if (this.files.length > 0) {
                if (this.files[0].size / (1024 * 1024) > 5) {
                    this.errorImages = true;
                    this.errorMessage = this.$t('validations.imageSize')
                } else {
                    this.errorImages = false;
                    this.imageUploading = true;
                    try {
                        const eventsAPI = new EventsAPI();
                        this.bgImageUrl = await eventsAPI.uploadEventImage({
                            token: this.userLogged.token,
                            eventId: this.selectedEventId,
                            imageType: 'EVENT_GALLERY',
                            image: this.files[0].sourceFile
                        })

                        this.imageUploading = false;
                        await this.loadGalleryImages();
                        this.$toast(this.$t('myEvents.successImage'));

                    } catch (error) {
                        this.imageUploading = false;
                        this.errorMessage = error;
                        this.errorImages = true;
                    }
                }
            }
        }
    }
}
</script>

<style scoped>
.images-container {
    margin-top: 20px;
    text-align: center;
}

.gallery-container {
    margin: 20px;
}
</style>