<template>
    <div class="header">
        <ui-menu-anchor class="corner">
            <ui-icon-button class="corner-button" icon="language" @click="openMenu = true">
            </ui-icon-button>
            <ui-menu v-model="openMenu" :items="languages" @selected="changeLanguage($event.value)"></ui-menu>
        </ui-menu-anchor>
    </div>
    <ui-tabs v-model="active">
        <ui-tab> {{ $t('home.about') }} </ui-tab>
        <ui-tab>{{ $t('home.pricing') }} </ui-tab>
        <ui-tab>{{ $t('home.help') }} </ui-tab>
    </ui-tabs>

    <ui-panels v-model="active">
        <ui-panel>
            <td-about></td-about>
        </ui-panel>
        <ui-panel>
            <td-pricing></td-pricing>
        </ui-panel>
        <ui-panel>
            <td-help></td-help>
        </ui-panel>
    </ui-panels>
    <div class="bottom-buttons">
        <ui-button raised class="button" id="create-button" icon="person_add" @click.prevent="goToCreateAccount">
            {{ $t('home.create') }}
        </ui-button>
        <ui-button raised class="button" icon="login" @click.prevent="goToLogin">
            {{ $t('home.login') }}
        </ui-button>
    </div>
</template>

<script>

import { useMainStore } from '@/stores/main';
import { mapActions } from 'pinia';
import TdAbout from '@/components/home/TdAbout.vue';
import TdPricing from '@/components/home/TdPricing.vue';
import TdHelp from '@/components/home/TdHelp.vue';

const languages = [
    {
        text: 'Español',
        value: 'es'
    },
    {
        text: 'English',
        value: 'en'
    }
];

export default {
    name: 'HomePage',
    components: { TdAbout, TdPricing, TdHelp },
    data() {
        return {
            languages: languages,
            openMenu: false,
            active: 0
        };
    },
    methods: {
        ...mapActions(useMainStore, ['updateDefaultLanguage']),
        goToLogin() {
            this.$router.push("/login")
        },
        goToCreateAccount() {
            this.$router.push("/login")
        },
        changeLanguage(value) {
            this.$i18n.locale = value
            this.updateDefaultLanguage(value)
        }
    }
}
</script>

<style scoped>
.corner {
    position: absolute;
    left: 0;
    bottom: 0;
}

.corner-button {
    margin-left: 16px;
    margin-bottom: 16px;
    color: #652d66;
    background: lightgray;
    border-radius: 8px;
}

.header {
    aspect-ratio: 3 / 1;
    position: relative;
    background: url('@/assets/home/main_banner.jpg') no-repeat;
    background-size: cover;
}

.bottom-buttons {
    position: -webkit-sticky;
    position: sticky;
    background-color: #ffffffcc;
    z-index: 1;
    min-height: 80px;
    padding-right: 8px;
    bottom: 0;
    text-align: right;
}

.button {
    margin: 16px 8px;
    height: 50px;
    width: 300px;
    font-size: medium;
}

#create-button {
    background: #e5a946;
}
</style>