import { URLS, LS_KEYS } from "@/utils/constants";
import commonsApi from "./CommonsApi";
import i18n from "@/i18n";

export default class UserAPI {

    async login(userModel) {

        let capcha = await commonsApi.getCaptcha();

        const loginUrl = userModel.loginType === "producer" ? URLS.OWNER_LOGIN : URLS.STAFF_LOGIN;

        const loginBody = userModel.loginType === "producer" ? {
            correo: userModel.email,
            password: userModel.password,
        } : {
            userId: userModel.email,
            password: userModel.password,
        }

        let response = await fetch(loginUrl, {
            method: "POST",
            body: JSON.stringify(loginBody),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let userData = await response.json();
            localStorage.setItem(LS_KEYS.USER_LOGIN_KEY, JSON.stringify(userData));
            return userData;
        }

        if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    logout() {
        localStorage.removeItem(LS_KEYS.USER_LOGIN_KEY);
    }

    async signInWithGoogle(externalSignInModel) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.GOOGLE_OWNER_SIGNIN, {
            method: "POST",
            body: JSON.stringify({
                idToken: externalSignInModel.idToken
            }),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let userData = await response.json();
            localStorage.setItem(LS_KEYS.USER_LOGIN_KEY, JSON.stringify(userData));
            return userData;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    }

    async createOwner(ownerModel) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.CREATE_OWNER, {
            method: "POST",
            body: JSON.stringify(ownerModel),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let ownerData = await response.json();
            return ownerData;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    }

    async signInWithApple(appleAuthorizationModel) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.APPLE_OWNER_SIGNIN, {
            method: "POST",
            body: JSON.stringify(appleAuthorizationModel),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let userData = await response.json();
            localStorage.setItem(LS_KEYS.USER_LOGIN_KEY, JSON.stringify(userData));
            return userData;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    }

    async resendValidationEmail(userModel) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.RESEND_VALIDATION_EMAIL, {
            method: "POST",
            body: JSON.stringify({
                correo: userModel.email,
            }),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            return null;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    }

    async requestForgotCode(userModel) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.REQUEST_FORGOT_CODE, {
            method: "POST",
            body: JSON.stringify({
                correo: userModel.email,
            }),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            return null;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    }

    async validateForgotCode(userModel) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.VALIDATE_FORGOT_CODE, {
            method: "POST",
            body: JSON.stringify({
                user: userModel.email,
                token: userModel.codigo
            }),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            return null;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    }

    async updateUserPassword(userModel) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.UPDATE_USER_PASSWORD, {
            method: "POST",
            body: JSON.stringify({
                resetToken: userModel.token,
                newPassword: userModel.newPassword,
                user: userModel.email
            }),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            return null;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    }

    async getUserData(payload) {

        let capcha = await commonsApi.getCaptcha();

        const getDataUrl = payload.sessionType === "OWNER" ? URLS.OWNER_GET_USER_DATA : URLS.STAFF_GET_USER_DATA;

        let response = await fetch(getDataUrl, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: payload.token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let userData = await response.json();
            //console.log(userData);
            return userData;
        }

        if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async getStripeData(payload) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.OWNER_GET_STRIPE_ACCOUNT_DATA + '?connectAccountId=' + payload.connectAccountId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: payload.token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let stripeSession = await response.json();
            //console.log(stripeSession);
            return stripeSession;
        }

        if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async validatePassword(payload) {

        let capcha = await commonsApi.getCaptcha();

        //console.log(payload);

        const passwordValidationUrl = payload.sessionType === "OWNER" ? URLS.OWNER_PASS_VALIDATION : URLS.STAFF_PASS_VALIDATION;

        let response = await fetch(passwordValidationUrl, {
            method: "POST",
            body: JSON.stringify({
                correo: payload.correo,
                password: payload.password,
            }),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: payload.token
            },
        });

        if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            return response.status === 200
        }
    }

    async updateUserData(payload) {

        let capcha = await commonsApi.getCaptcha();

        //console.log(payload);

        const updateUserUrl = payload.sessionType === "OWNER" ? URLS.OWNER_UPDATE_USER_DATA : URLS.STAFF_UPDATE_USER_DATA;

        const updateBody = payload.sessionType === "OWNER" ? {
            correo: payload.correo,
            nombre: payload.nombre,
            password: payload.password,
            apellidos: payload.apellidos,
        } : {
            userId: payload.correo,
            nombre: payload.nombre,
            password: payload.password,
            apellidos: payload.apellidos
        }

        let response = await fetch(updateUserUrl, {
            method: "PUT",
            body: JSON.stringify(updateBody),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: payload.token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let userData = await response.json();
            localStorage.setItem(LS_KEYS.USER_LOGIN_KEY, JSON.stringify(userData));
            //console.log(userData);
            return userData;
        }

        if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async sendContactForm(formModel) {
        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.SEND_CONTACT_FORM, {
            method: "POST",
            body: JSON.stringify({
                nombre: formModel.fullName,
                correo: formModel.email,
                mensaje: formModel.message,
                contactCategory: formModel.contactType
            }),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            return;
        }
        else if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }

    }

    async addStaff(token, staffModel) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.ADD_STAFF, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            },
            body: JSON.stringify(staffModel)
        });

        if (response.status >= 200 && response.status < 300) {
            let staffData = await response.json();
            return staffData;
        } else if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async updateStaff(token, staffModel) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.UPDATE_STAFF, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            },
            body: JSON.stringify(staffModel)
        });

        if (response.status >= 200 && response.status < 300) {
            let staffData = await response.json();
            return staffData;
        } else if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async removeStaff(token, staffModel) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.REMOVE_STAFF + "/" + staffModel.events[0].id + "/" + staffModel.userId, {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            },
        });

        if (response.status >= 200 && response.status < 300) {
            return
        } else if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async getStaff(token, eventId) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.GET_STAFF + "/" + eventId, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            },
        });

        if (response.status >= 200 && response.status < 300) {
            let staffData = await response.json();
            return staffData;
        } else if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async activateStaff(token, staffModel) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.ACTIVATE_STAFF, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            },
            body: JSON.stringify(staffModel)
        });

        if (response.status >= 200 && response.status < 300) {
            let staffData = await response.json();
            return staffData;
        } else if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async deactivateStaff(token, staffModel) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.DEACTIVATE_STAFF, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            },
            body: JSON.stringify(staffModel)
        });

        if (response.status >= 200 && response.status < 300) {
            let staffData = await response.json();
            return staffData;
        } else if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }
}