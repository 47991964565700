<template>
    <div class="container">
        <div v-if="textAlign === 'left'" class="item">
            <p class="title">
                {{ title }}
            </p>
            <p class="message">
                {{ message }}
            </p>
        </div>
        <div class="item">
            <img :src="imgUrl" :width="imgWidth" :height="imgHeight" />
        </div>
        <div v-if="textAlign === 'right'" class="item">
            <p class="title">
                {{ title }}
            </p>
            <p class="message">
                {{ message }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: "TdImageTextTile",
    props: {
        textAlign: String,
        title: String,
        message: String,
        imgUrl: String,
        imgWidth: Number,
        imgHeight: Number
    }
}
</script>

<style scoped>
.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.item {
    margin-left: 20px;
    margin-right: 20px
}

.title {
    text-align: center;
    font-size: x-large;
    font-weight: bolder;
}

.message {
    text-align: center;
    padding-left: 50px;
    padding-right: 50px;
}
</style>