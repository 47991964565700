import { createRouter, createWebHistory } from 'vue-router'
import { useMainStore } from '@/stores/main';
import HomePage from '@/views/HomePage.vue'
import LoginPage from '@/views/LoginPage.vue'
import RegistrarBoletoPage from '@/views/RegistrarBoletoPage.vue'
import ReportesPage from '@/views/ReportesPage.vue'
import ListadoEntradasPage from '@/views/ListadoEntradasPage.vue'
import ListadoPagosPage from '@/views/ListadoPagosPage.vue'
import PerfilUsuarioPage from '@/views/PerfilUsuarioPage.vue'
import NoContentPage from '@/views/NoContentPage.vue'
import MisEventosPage from '@/views/MisEventosPage.vue'
import ListadoTransferenciasPage from '@/views/ListadoTransferenciasPage.vue'
import ForgotPasswordPage from '@/views/ForgotPasswordPage.vue'
import i18n from "@/i18n"

const routes = [
  /*{
    path: '/',
    name: 'home',
    component: LoginPage,
  },*/
  {
    path: '/',
    name: 'home',
    component: HomePage,
  },
  {
    path: '/login',
    component: LoginPage,
    meta: {
      requiresLogout: true,
    }
  },
  {
    path: '/forgot-password',
    component: ForgotPasswordPage,
    meta: {
      requiresLogout: true,
    }
  },
  {
    path: '/reportes',
    component: ReportesPage,
    meta: {
      requiresOwner: true,
    }
  },
  {
    path: '/mis-eventos',
    component: MisEventosPage,
    meta: {
      requiresOwner: true,
    }
  },
  {
    path: '/listado-pagos',
    component: ListadoPagosPage,
    meta: {
      requiresOwner: true,
    }
  },
  {
    path: '/listado-transferencias',
    component: ListadoTransferenciasPage,
    meta: {
      requiresOwner: true,
    }
  },
  {
    path: '/listado-entradas',
    component: ListadoEntradasPage,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/registrar',
    component: RegistrarBoletoPage,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/perfil',
    component: PerfilUsuarioPage,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/:catchAll(.*)*',
    component: NoContentPage,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
  scrollBehavior() {
    return {
      top: 0
    }
  }
})

router.beforeEach(to => {

  const mainStore = useMainStore();

  if (to.meta.requiresAuth && !mainStore.userLogged) {
    // This route requires auth, check if logged in.
    // If not, redirect to login page.
    return {
      path: "/login",
      // save the location we are trying to access before Logging in, in order to come back later
      query: { redirect: to.fullPath }
    };

  } else if (to.meta.requiresOwner) {
    // this route requires Owner auth only, check if logged in
    // if not, redirect to a not-found page.
    if (mainStore.userLogged && mainStore.userLogged.sessionType === 'OWNER') {
      return
    } else {
      return {
        path: "/not-found"
      }
    }
  } else if (to.meta.requiresLogout && mainStore.userLogged) {
    // this route requires that the user is not logged in.
    // If the user is logged in, it will be redirected to home.
    return {
      path: "/perfil"
    }
  } else {
    mainStore.$patch({ pageTitle: i18n.global.t('titles.main') })
    return
  }
})

export default router
