<template>
  <h2> {{ $t('purchases.title') }}</h2>
  <div id="event-selector">
    <ui-grid>
      <ui-grid-cell columns="8">
        <event-selector @on-selected-event="updateTable" />
      </ui-grid-cell>
      <ui-grid-cell columns="4">
        <entry-type-selector @on-selected-entry-type="updateTable" />
      </ui-grid-cell>
    </ui-grid>
  </div>
  <div class="content">
    <error-component v-show="errorTable" :message="errorMessage" />
    <ui-skeleton :loading="loadingTable" :title="false" active :paragraph="{ rows: 20 }">
      <tabla-pagos-boletos :payments-tickets="paymentsTickets" @refresh-payments-tickets="updateTable"
        v-if="showTickets" />
      <tabla-pagos-registros :payments-registries="paymentsRegistries" @refresh-payments-registries="updateTable"
        v-else-if="showRegistries" />
    </ui-skeleton>
  </div>
</template>
<script>
import { useMainStore } from '@/stores/main';
import { mapState } from 'pinia';
import EventSelector from "@/components/common/EventSelector.vue";
import EntryTypeSelector from "@/components/common/EntryTypeSelector.vue";
import TablaPagosBoletos from "@/components/pagos/TablaPagosBoletos.vue";
import TablaPagosRegistros from "@/components/pagos/TablaPagosRegistros.vue";
import ErrorComponent from "@/components/common/ErrorComponent.vue";
import PaymentsAPI from "@/api/PaymentsAPI";
import i18n from "@/i18n"

export default {
  name: "ListadoPagosPage",
  created() {

    const mainStore = useMainStore()
    mainStore.$patch({ pageTitle: i18n.global.t('titles.listOfPurchases') })
    //console.log("ListadoPagosPage created");
  },
  components: {
    EventSelector,
    EntryTypeSelector,
    TablaPagosBoletos,
    TablaPagosRegistros,
    ErrorComponent,
  },
  data() {
    return {
      paymentsTickets: [],
      paymentsRegistries: [],
      loadingTable: false,
      errorTable: false,
      errorMessage: "",
      showTickets: false,
      showRegistries: false,
    };
  },
  computed: {
    ...mapState(useMainStore, ['userLogged', 'selectedType', 'selectedEventId']),
  },
  methods: {
    async updateTable() {
      this.loadingTable = true;
      this.errorTable = false;
      const paymentsAPI = new PaymentsAPI();
      try {
        if (this.selectedType === "boletos") {
          const paymentsData = await paymentsAPI.getTicketPayments({
            token: this.userLogged.token,
            eventId: this.selectedEventId,
          });
          //console.log(paymentsData);
          this.stripeUrl = paymentsData.urlStripe;
          this.paymentsTickets = [...paymentsData.ticketPayments];
          this.showTickets = true;
          this.showRegistries = false;
        } else if (this.selectedType === "registros") {
          const paymentsData = await paymentsAPI.getRegistryPayments({
            token: this.userLogged.token,
            eventId: this.selectedEventId,
          });
          //console.log(this.paymentsData);
          this.stripeUrl = paymentsData.urlStripe;
          this.paymentsRegistries = [...paymentsData.registryPayments];
          this.showRegistries = true;
          this.showTickets = false;
        }
      } catch (error) {
        this.errorTable = true;
        this.errorMessage = error;
        this.showTickets = false;
        this.showRegistries = false;
      }
      this.loadingTable = false;
    },
  },
};
</script>
<style scoped>
h2,
h3 {
  text-align: center;
  margin-top: 2em;
}

#event-selector {
  margin: auto;
  width: 75%;
}

.content {
  margin: auto;
  width: 90%;
}
</style>