import { createApp } from 'vue'
import App from './App.vue'
import BalmUI from 'balm-ui'; // Official Google Material Components
import BalmUIPlus from 'balm-ui/dist/balm-ui-plus'; // BalmJS Team Material Components
import 'balm-ui-css'
import validator from './utils/validator';
import router from './router'
import { createPinia } from 'pinia'
import i18n from './i18n'

const pinia = createPinia()
const app = createApp(App)

app.use(BalmUI, {
    $theme: {
        primary: "#652d66",
        secondary: "#e5a946"
    },
    $validator: validator,
    $toast: {
        position: 'center'
    }
});
app.use(i18n)
app.use(router)
app.use(BalmUIPlus)
app.use(pinia)
app.mount('#app')