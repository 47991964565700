<template>
    <div>
        <p class="title">
            <img :src="imgUrl" :width="imgWidth" :height="imgHeight" />
        </p>
        <p class="title" v-if="title">
            {{ title }}
        </p>
        <p class="message">
            {{ message }}
        </p>
    </div>
</template>

<script>

export default {
    name: "TdTile",
    props: {
        title: String,
        message: String,
        imgUrl: String,
        imgWidth: Number,
        imgHeight: Number
    }
}

</script>

<style scoped>
.title {
    text-align: center;
    font-size: x-large;
    font-weight: bold;
}

.message {
    color: #333;
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 7;
}
</style>