<template>
    <div class="purple-container">
        <h1 class="subtitle light"> {{ $t('home.faqs') }}</h1>
        <div v-shape class="faqs-container">
            <ui-collapse v-for="(value, index) in numberOfQuestions" :key="index" with-icon ripple>
                <template #toggle>
                    <div>
                        <h3> {{ questions[index] }} </h3>
                    </div>
                </template>
                <div>
                    <p> {{ answers[index] }} </p>
                </div>
            </ui-collapse>
        </div>
    </div>
    <table class="contact-title">
        <tr>
            <td>
                <img src="@/assets/home/4.3.svg" width="60" height="60" />
            </td>
            <td>
                <h1 class="subtitle"> {{ $t('home.contactForm') }}</h1>
            </td>
        </tr>
    </table>
    <div style="margin-bottom: 40px;">
        <ui-card class="form-container" v-shadow.transition="[2, 16]">
            <ui-form type="|" item-margin-bottom="10" action-align="right">
                <template #default="{ subitemClass, actionClass }">
                    <ui-form-field :class="subitemClass">
                        <ui-select fullwidth v-model="formData.contactType" :options="contactOptions" outlined
                            icon="contact_support">
                            {{
            $t('home.contactType')
        }}</ui-select>
                    </ui-form-field>
                    <ui-form-field :class="subitemClass">
                        <ui-textfield input-type="email" outlined fullwidth v-model="formData.email"
                            helper-text-id="email-helper-text">
                            {{ $t('contactForm.email') }}
                            <template #before>
                                <ui-textfield-icon>email</ui-textfield-icon>
                            </template>
                        </ui-textfield>
                        <ui-textfield-helper id="email-helper-text"
                            v-model:validMsg="validMsg.email"></ui-textfield-helper>
                    </ui-form-field>
                    <ui-form-field :class="subitemClass">
                        <ui-textfield outlined fullwidth v-model="formData.fullName" :disabled="formLoading">
                            {{ $t('contactForm.fullName') }}
                            <template #before>
                                <ui-textfield-icon>person</ui-textfield-icon>
                            </template>
                        </ui-textfield>
                    </ui-form-field>
                    <ui-form-field :class="subitemClass">
                        <ui-textfield outlined input-type="textarea" fullwidth rows="8" v-model="formData.message"
                            helper-text-id="message-helper-text">
                            {{ $t('contactForm.message') }}
                        </ui-textfield>
                        <ui-textfield-helper id="message-helper-text"
                            v-model:validMsg="validMsg.message"></ui-textfield-helper>
                    </ui-form-field>
                    <ui-form-field :class="actionClass">
                        <ui-button raised native-type="submit" @click.prevent="sendForm" @keyup.enter="sendForm"
                            :disabled="formLoading"> {{ $t('actions.send') }}
                            <template #after="{ iconClass }">
                                <ui-icon :class="iconClass">send</ui-icon>
                            </template>
                        </ui-button>
                    </ui-form-field>
                </template>
            </ui-form>
            <ui-progress v-show="formLoading" indeterminate active></ui-progress>
            <error-component v-show="errorSend" :message="errorMessage" />
        </ui-card>
    </div>
</template>

<script>

import { useValidator } from "balm-ui";
import i18n from '@/i18n';
import ErrorComponent from "@/components/common/ErrorComponent.vue";
import { RECAPTCHA_ACTIONS } from "@/utils/constants";
import { useMainStore } from '@/stores/main';
import { mapActions } from 'pinia';
import commonsApi from "@/api/CommonsApi";

const validations = [
    {
        key: "email",
        label: i18n.global.t('contactForm.email'),
        validator: "required, validEmail",
    },
    {
        key: "message",
        label: i18n.global.t('contactForm.message'),
        validator: "required",
    },
]

export default {
    name: "TdHelp",
    components: { ErrorComponent },
    computed: {
        questions() {
            return [
                this.$t('faqs.q1'),
                this.$t('faqs.q2'),
                this.$t('faqs.q3'),
                this.$t('faqs.q4'),
                this.$t('faqs.q5'),
                this.$t('faqs.q6'),
                this.$t('faqs.q7'),
                this.$t('faqs.q8'),
                this.$t('faqs.q9')
            ]
        },
        answers() {
            return [
                this.$t('faqs.a1'),
                this.$t('faqs.a2'),
                this.$t('faqs.a3'),
                this.$t('faqs.a4'),
                this.$t('faqs.a5'),
                this.$t('faqs.a6'),
                this.$t('faqs.a7'),
                this.$t('faqs.a8'),
                this.$t('faqs.a9')
            ]
        },
        numberOfQuestions() {
            return this.questions.length
        },
        contactOptions() {
            return [
                {
                    label: this.$t('contactCategories.sales'),
                    value: 'VENTAS'
                },
                {
                    label: this.$t('contactCategories.consulting'),
                    value: 'CONSULTORIA_SOPORTE'
                },
                {
                    label: this.$t('contactCategories.other'),
                    value: 'OTRO'
                }
            ]
        }
    },
    data() {
        return {
            balmUI: useValidator(),
            validations,
            validMsg: {},
            formData: {
                contactType: "",
                email: "",
                fullName: "",
                message: ""
            },
            formLoading: false,
            errorSend: false,
            errorMessage: "",
        }
    },
    methods: {
        ...mapActions(useMainStore, ['sendContactForm']),
        async sendForm() {
            let result = this.balmUI.validate(this.formData);
            let { valid, validMsg } = result;
            this.validMsg = validMsg;

            if (valid) {
                window.grecaptcha.enterprise.ready(async () => {

                    try {

                        this.formLoading = true;
                        this.errorSend = false;

                        const token = await window.grecaptcha.enterprise.execute(process.env.VUE_APP_RECAPTCHA_KEY, { action: RECAPTCHA_ACTIONS.SUPPORT });
                        await commonsApi.checkReCaptcha(RECAPTCHA_ACTIONS.SUPPORT, token, process.env.VUE_APP_RECAPTCHA_KEY)

                        await this.sendContactForm(this.formData);

                        this.formLoading = false;
                        this.formData.email = ""
                        this.formData.fullName = ""
                        this.formData.message = ""

                        this.$alert({
                            message: this.$t('home.successContact'),
                            state: 'success'
                        });

                    } catch (error) {
                        this.formLoading = false;
                        this.errorMessage = error;
                        this.errorSend = true;
                        //console.log(error);
                    }

                });
            }
        }
    }
}
</script>

<style scoped>
.subtitle {
    text-align: center;
    margin: 40px;
}

.subtitle.light {
    color: white;
}

.purple-container {
    background-color: #652d66dd;
    padding: 20px;
}

.faqs-container {
    max-width: 60%;
    margin: auto;
    background: white;
    padding: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
}

h3 {
    margin: 20px 50px;
}

p {
    text-align: justify;
    margin: 20px 50px;
}

.contact-title {
    margin: 0 auto;
}

.form-container {
    max-width: 540px;
    margin: 0 auto;
    padding: 40px;
}
</style>