<template>
    <ui-card class="card-container" v-shadow.transition="[2, 8]">
        <ui-form type="|" item-margin-bottom="20" action-align="center">
            <template #default="{ itemClass, actionClass }">
                <ui-form-field :class="itemClass">
                    <h2> {{ $t('user.newPassword') }}</h2>
                </ui-form-field>
                <ui-form-field :class="itemClass">
                    <ui-textfield input-type="password" outlined fullwidth v-model="formData.password"
                        helper-text-id="nuevo-password-helper-text" :disabled="formLoading || successUpdate">
                        {{ $t('user.newPassword') }}
                        <template #before>
                            <ui-textfield-icon>vpn_key</ui-textfield-icon>
                        </template>
                    </ui-textfield>
                    <ui-textfield-helper id="nuevo-password-helper-text" v-model:validMsg="validMsg.password">
                    </ui-textfield-helper>
                </ui-form-field>

                <ui-form-field :class="itemClass">
                    <h2>{{ $t('user.confirmNewPassword') }}</h2>
                </ui-form-field>
                <ui-form-field :class="itemClass">
                    <ui-textfield input-type="password" outlined fullwidth v-model="formData.nuevoPasswordConfirmar"
                        helper-text-id="nuevo-password-confirmar-helper-text" :disabled="formLoading || successUpdate">
                        {{ $t('user.confirmNewPassword') }}
                        <template #before>
                            <ui-textfield-icon>vpn_key</ui-textfield-icon>
                        </template>
                    </ui-textfield>
                    <ui-textfield-helper id="nuevo-password-confirmar-helper-text"
                        v-model:validMsg="validMsg.nuevoPasswordConfirmar"></ui-textfield-helper>
                </ui-form-field>
                <ui-form-field :class="actionClass">
                    <ui-button raised native-type="submit" @click.prevent="savePassword"
                        :disabled="formLoading || successUpdate">
                        {{ $t('actions.save') }}
                        <template #after="{ iconClass }">
                            <ui-icon :class="iconClass">save</ui-icon>
                        </template>
                    </ui-button>
                </ui-form-field>
            </template>
        </ui-form>
        <ui-progress v-show="formLoading" indeterminate active></ui-progress>
        <error-component v-show="errorReset" :message="errorMessage" />
        <ui-button id="goLogin" v-show="successUpdate" @click.prevent="goToLogin"> {{ $t('titles.enter') }}
            <template #after="{ iconClass }">
                <ui-icon :class="iconClass">arrow_forward_ios</ui-icon>
            </template>
        </ui-button>
    </ui-card>
</template>
<script>
import { useValidator } from "balm-ui";
import ErrorComponent from "@/components/common/ErrorComponent.vue";
import i18n from "@/i18n";
import UserAPI from "@/api/UserAPI";

const validations = [
    {
        key: "password",
        label: "Password",
        validator: "required, validPassword",
    },
    {
        key: "nuevoPasswordConfirmar",
        label: i18n.global.t("user.confirmNewPassword"),
        validator: "required, confirmPassword",
    },
];

export default {
    name: "ResetPasswordForm",
    components: {
        ErrorComponent,
    },
    props: {
        email: String,
        token: String,
    },
    data() {
        return {
            balmUI: useValidator(),
            validations,
            validMsg: {},
            formData: {
                password: "",
                nuevoPasswordConfirmar: "",
            },
            formLoading: false,
            errorReset: false,
            errorMessage: "",
            successUpdate: false,
        };
    },
    methods: {
        async savePassword() {
            let result = this.balmUI.validate(this.formData);
            //console.log(result);
            let { valid, validMsg } = result;
            this.validMsg = validMsg;

            if (valid) {
                this.formLoading = true;
                this.errorReset = false;

                try {
                    const userApi = new UserAPI();
                    await userApi.updateUserPassword({
                        token: this.token,
                        newPassword: this.formData.password,
                        email: this.email,
                    });

                    this.formLoading = false;
                    this.successUpdate = true;
                    this.formData.password = "";
                    this.formData.nuevoPasswordConfirmar = "";

                    this.$alert({
                        title: this.$t('commonElements.important'),
                        message: this.$t('user.updateConfirmation'),
                        state: "success",
                    });
                } catch (error) {
                    this.formLoading = false;
                    this.errorMessage = error.mensaje;
                    this.errorReset = true;
                }
            }
        },
        goToLogin() {
            this.$router.push("/login");
        },
    },
};
</script>
<style scoped>
label {
    text-align: justify;
}

.card-container {
    padding: 2em;
    margin-top: 3em;
    min-height: 620px;
}

#goLogin {
    margin-top: 2em;
}
</style>
