<template>
    <swiper :modules="modules" :slides-per-view="1" :space-between="30" navigation>
        <swiper-slide>
            <ui-card elevated class=" demo-card demo-card--music">
                <ui-card-content class="demo-card__primary-action">
                    <div class="demo-card__music-row">
                        <ui-card-media id="demo-card__media-guadalayork" square
                            class="demo-card__media--music"></ui-card-media>
                        <div class="demo-card__music-info">
                            <div :class="[$tt('headline4'), 'demo-card__music-title']">Guadalayork</div>
                            <div class="demo-card__music-artist">{{ guadalayorkSubtitle }}</div>
                            <div class="demo-card__music-year"><a target="_blank"
                                    href="https://www.instagram.com/guadalayork?igsh=NHpnMmh5azRoajVw">Ir a perfil de
                                    redes solciales</a></div>
                        </div>
                    </div>
                </ui-card-content>
                <ui-list-divider></ui-list-divider>
                <ui-card-actions>
                    <ui-card-buttons class="demo-card__action-buttons--text-only">
                        {{ guadalayorkDescription }}
                    </ui-card-buttons>
                    <ui-card-icons>
                        <ui-icon v-for="i in 5" :key="i" class="demo-card__action-icon--star"
                            :title="`${i} star${i > 1 ? 's' : ''}`" size="36">
                            star
                        </ui-icon>
                    </ui-card-icons>
                </ui-card-actions>
            </ui-card>
        </swiper-slide>
        <swiper-slide>
            <ui-card elevated class="demo-card demo-card--music">
                <ui-card-content class="demo-card__primary-action">
                    <div class="demo-card__music-row">
                        <ui-card-media id="demo-card__media-just4u" square
                            class="demo-card__media--music"></ui-card-media>
                        <div class="demo-card__music-info">
                            <div :class="[$tt('headline4'), 'demo-card__music-title']">Just 4U</div>
                            <div class="demo-card__music-artist">{{ just4uSubtitle }}</div>
                            <div class="demo-card__music-year"><a target="_blank"
                                    href="https://www.instagram.com/just4udance?igsh=YjZlYmJicXAycHpm">Ir a perfil de
                                    redes solciales</a></div>
                        </div>
                    </div>
                </ui-card-content>
                <ui-list-divider></ui-list-divider>
                <ui-card-actions>
                    <ui-card-buttons class="demo-card__action-buttons--text-only">
                        {{ just4uDescription }}
                    </ui-card-buttons>
                    <ui-card-icons>
                        <ui-icon v-for="i in 5" :key="i" class="demo-card__action-icon--star"
                            :title="`${i} star${i > 1 ? 's' : ''}`" size="36">
                            star
                        </ui-icon>
                    </ui-card-icons>
                </ui-card-actions>
            </ui-card>
        </swiper-slide>
        <swiper-slide>
            <ui-card elevated class="demo-card demo-card--music">
                <ui-card-content class="demo-card__primary-action">
                    <div class="demo-card__music-row">
                        <ui-card-media id="demo-card__media-csbf" square
                            class="demo-card__media--music"></ui-card-media>
                        <div class="demo-card__music-info">
                            <div :class="[$tt('headline4'), 'demo-card__music-title']">Cancún Salsa & Bachata Fest</div>
                            <div class="demo-card__music-artist">{{ csbfSubtitle }}</div>
                            <div class="demo-card__music-year"><a target="_blank"
                                    href="https://www.instagram.com/cancunsalsabachatafest?igsh=MTh1cWZ6MzR1M2c3MA==">Ir
                                    a perfil de
                                    redes solciales</a></div>
                        </div>
                    </div>
                </ui-card-content>
                <ui-list-divider></ui-list-divider>
                <ui-card-actions>
                    <ui-card-buttons class="demo-card__action-buttons--text-only">
                        {{ csbfDescription }}
                    </ui-card-buttons>
                    <ui-card-icons>
                        <ui-icon v-for="i in 5" :key="i" class="demo-card__action-icon--star"
                            :title="`${i} star${i > 1 ? 's' : ''}`" size="36">
                            star
                        </ui-icon>
                    </ui-card-icons>
                </ui-card-actions>
            </ui-card>
        </swiper-slide>
        <swiper-slide>
            <ui-card elevated class="demo-card demo-card--music">
                <ui-card-content class="demo-card__primary-action">
                    <div class="demo-card__music-row">
                        <ui-card-media id="demo-card__media-ballare" square
                            class="demo-card__media--music"></ui-card-media>
                        <div class="demo-card__music-info">
                            <div :class="[$tt('headline4'), 'demo-card__music-title']">Ballare Salsa Social</div>
                            <div class="demo-card__music-artist">{{ ballareSubtitle }}</div>
                            <div class="demo-card__music-year"><a target="_blank"
                                    href="https://www.instagram.com/experiencia_ballare?igsh=MWdqbWVqNzJxaHl5bA==">Ir
                                    a perfil de
                                    redes solciales</a></div>
                        </div>
                    </div>
                </ui-card-content>
                <ui-list-divider></ui-list-divider>
                <ui-card-actions>
                    <ui-card-buttons class="demo-card__action-buttons--text-only">
                        {{ ballareDescription }}
                    </ui-card-buttons>
                    <ui-card-icons>
                        <ui-icon v-for="i in 5" :key="i" class="demo-card__action-icon--star"
                            :title="`${i} star${i > 1 ? 's' : ''}`" size="36">
                            star
                        </ui-icon>
                    </ui-card-icons>
                </ui-card-actions>
            </ui-card>
        </swiper-slide>
        <swiper-slide>
            <div class="join">
                <p style="font-size: 4em;"> {{ join }}</p>
                <p><ui-icon id="thumb-up" size="48">thumb_up_alt</ui-icon></p>
            </div>
        </swiper-slide>
    </swiper>
</template>

<script>

import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

export default {
    components: {
        Swiper, SwiperSlide
    },
    computed: {
        guadalayorkSubtitle() {
            return this.$t('home.guadalayorkSubtitle')
        },
        guadalayorkDescription() {
            return this.$t('home.guadalayorkDescription')
        },
        just4uSubtitle() {
            return this.$t('home.just4uSubtitle')
        },
        just4uDescription() {
            return this.$t('home.just4uDescription')
        },
        csbfSubtitle() {
            return this.$t('home.csbfSubtitle')
        },
        csbfDescription() {
            return this.$t('home.csbfDescription')
        },
        ballareSubtitle() {
            return this.$t('home.ballareSubtitle')
        },
        ballareDescription() {
            return this.$t('home.ballareDescription')
        },
        join() {
            return this.$t('home.join')
        }
    },
    data() {
        return {
            modules: [Navigation],
        }
    }
}


</script>

<style scoped lang="scss">
@use '@material/card/mixins' as card-mixins;
@use '@material/rtl/mixins' as rtl-mixins;

:deep(.swiper-button-next),
:deep(.swiper-button-prev) {
    color: #ffffff;
}

.demo-card {
    width: 720px;
    height: 500px;
    padding: 20px;
    margin: 0 auto;
}

#demo-card__media-guadalayork {
    position: relative;
    background: url('@/assets/home/guadalayork.jpg') no-repeat;
    background-size: cover;
    width: 400px;
    height: 250px;
}

#demo-card__media-just4u {
    position: relative;
    background: url('@/assets/home/just4u.jpg') no-repeat;
    background-size: cover;
    width: 400px;
    height: 250px;
}

#demo-card__media-csbf {
    position: relative;
    background: url('@/assets/home/csbf.jpg') no-repeat;
    background-size: cover;
    width: 600px;
    height: 250px;
}

#demo-card__media-ballare {
    position: relative;
    background: url('@/assets/home/ballare.png') no-repeat;
    background-size: cover;
    width: 250px;
    height: 250px;
}

.demo-card__primary-action {
    margin-bottom: 20px;
}

.demo-card--music {
    @include card-mixins.shape-radius((24px, 4px), true);
}

.demo-card__music-row {
    display: flex;
    border-top-left-radius: inherit;

    @include rtl-mixins.rtl {
        border-top-left-radius: 0;
        border-top-right-radius: inherit;
    }
}

.demo-card__media--music {
    width: 110px;
    border-top-left-radius: inherit;

    @include rtl-mixins.rtl {
        border-top-left-radius: 0;
        border-top-right-radius: inherit;
    }
}

.demo-card__music-info {
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
}

.demo-card__action-buttons--text-only {
    margin-left: 8px;
    text-align: justify;
}

.demo-card__music-year {
    margin-top: 20px;
    font-size: large;
    font-weight: bold;
}

.demo-card__action-icon--star {
    margin-left: 4px;
    margin-right: 4px;
    cursor: pointer;
    color: gold;
}

.join {
    width: 720px;
    height: 500px;
    margin: 0 auto;
}

.join p {
    color: white;
    text-align: center;
}
</style>