<template>
    <div id="create-form-container">
        <ui-card id="card-container" v-shadow.transition="[2, 8]">
            <h3 style="text-align: center;"> {{ $t('user.create') }}</h3>
            <ui-list-divider />
            <ui-form type="|" item-margin-bottom="20" action-align="right">
                <template #default="{ subitemClass, actionClass }">
                    <ui-form-field style="margin-top: 20px" :class="subitemClass">
                        <ui-textfield input-type="email" outlined fullwidth v-model="formData.email"
                            helper-text-id="email-helper-text" :disabled="formLoading">
                            {{ $t('filters.email') }}
                            <template #before>
                                <ui-textfield-icon>mail</ui-textfield-icon>
                            </template>
                        </ui-textfield>
                        <ui-textfield-helper id="email-helper-text"
                            v-model:validMsg="validMsg.email"></ui-textfield-helper>
                    </ui-form-field>
                    <ui-form-field :class="subitemClass">
                        <ui-textfield input-type="password" outlined fullwidth helper-text-id="password-helper-text"
                            v-model="formData.password" :disabled="formLoading">
                            {{ $t('user.password') }}
                            <template #before>
                                <ui-textfield-icon>vpn_key</ui-textfield-icon>
                            </template>
                        </ui-textfield>
                        <ui-textfield-helper id="password-helper-text"
                            v-model:validMsg="validMsg.password"></ui-textfield-helper>
                    </ui-form-field>
                    <ui-form-field :class="subitemClass">
                        <ui-textfield input-type="password" outlined fullwidth
                            helper-text-id="password-confirmar-helper-text" v-model="formData.confirmPassword"
                            :disabled="formLoading">
                            {{ $t('user.confirmNewPassword') }}
                            <template #before>
                                <ui-textfield-icon>vpn_key</ui-textfield-icon>
                            </template>
                        </ui-textfield>
                        <ui-textfield-helper id="password-confirmar-helper-text"
                            v-model:validMsg="validMsg.confirmPassword">
                        </ui-textfield-helper>
                    </ui-form-field>
                    <ui-form-field :class="subitemClass">
                        <ui-checkbox v-model="formData.acceptedTerms" input-id="acceptedTerms"></ui-checkbox>
                        <label id="terms" for="acceptedTerms">{{ $t('user.createDisclosure1') }}
                            <a href="https://ticketdancer.com.mx/condiciones-uso" target="_blank">{{
            $t('user.createDisclosure2') }}</a>
                        </label>
                    </ui-form-field>
                    <ui-form-field :class="actionClass">
                        <ui-button raised native-type="submit" id="create-button"
                            :disabled="formLoading || !formData.acceptedTerms"
                            @click.prevent="validateAndOpenConfirmDialog"> {{
            $t('actions.createAccount')
        }}
                            <template #after="{ iconClass }">
                                <ui-icon :class="iconClass">person_add</ui-icon>
                            </template>
                        </ui-button>
                    </ui-form-field>
                </template>
            </ui-form>
            <ui-progress v-show="formLoading" indeterminate active></ui-progress>
            <error-component v-show="errorCreate" :message="errorMessage" />
        </ui-card>
    </div>
    <ui-dialog v-model="openConfirmCreate" fullscreen>
        <ui-dialog-title> {{ $t('commonElements.confirmation') }} </ui-dialog-title>
        <ui-dialog-content>
            <p>
                {{ $t('home.createUserConfirmation1') }}
                <br />
                <strong> {{ formData.email }}</strong>
                <br />
                <strong>NOTA: </strong>{{ $t('home.createUserConfirmation2') }}
            </p>
        </ui-dialog-content>
        <ui-dialog-actions>
            <ui-button @click.prevent="createOwner">{{ $t('actions.confirm') }}</ui-button>
        </ui-dialog-actions>
    </ui-dialog>

</template>

<script>

import { useValidator } from "balm-ui";
import i18n from "@/i18n";
import { RECAPTCHA_ACTIONS } from "@/utils/constants";
import ErrorComponent from "@/components/common/ErrorComponent.vue";
import UserAPI from '@/api/UserAPI.js';
import commonsApi from "@/api/CommonsApi";

const validations = [
    {
        key: "email",
        label: "Email",
        validator: "required, validEmail",
    },
    {
        key: "password",
        label: "Password",
        validator: "required, validPassword",
    },
    {
        key: "confirmPassword",
        label: i18n.global.t("user.confirmNewPassword"),
        validator: "required, confirmPassword",
    },
];

export default {
    components: { ErrorComponent },
    data() {
        return {
            balmUI: useValidator(),
            validations,
            validMsg: {},
            formData: {
                email: "",
                password: "",
                confirmPassword: "",
                acceptedTerms: false
            },
            openConfirmCreate: false,
            formLoading: false,
            errorCreate: false,
            errorMessage: "",
        }
    },
    methods: {
        validateAndOpenConfirmDialog() {
            let result = this.balmUI.validate(this.formData);
            let { valid, validMsg } = result;
            this.validMsg = validMsg;

            if (valid) {
                this.openConfirmCreate = true;
            }

        },
        async createOwner() {

            this.openConfirmCreate = false;

            window.grecaptcha.enterprise.ready(async () => {

                try {

                    this.formLoading = true;
                    this.errorCreate = false;

                    const token = await window.grecaptcha.enterprise.execute(process.env.VUE_APP_RECAPTCHA_KEY, { action: RECAPTCHA_ACTIONS.CREATE_OWNER });
                    await commonsApi.checkReCaptcha(RECAPTCHA_ACTIONS.CREATE_OWNER, token, process.env.VUE_APP_RECAPTCHA_KEY)

                    const userApi = new UserAPI();

                    const ownerData = await userApi.createOwner({
                        correo: this.formData.email.trim().toLowerCase(),
                        password: this.formData.password
                    });

                    this.formLoading = false;
                    this.resetForm();

                    this.$alert({
                        title: this.$t('commonElements.important'),
                        message: this.$t('home.createUserNotification', [ownerData.correo]),
                        state: "info",
                    });

                } catch (error) {
                    //console.log(error);
                    this.formLoading = false;
                    this.errorMessage = error.mensaje;
                    this.errorCreate = true;
                }

            });
        },
        resetForm() {
            this.formData.email = "";
            this.formData.password = "";
            this.formData.confirmPassword = "";
            this.formData.acceptedTerms = false;

        }
    }
}

</script>

<style scoped>
label {
    font-size: 1.2em;
    font-weight: 600;
    color: #222;
}

h5 {
    text-align: center;
    margin-top: 2em;
}

#create-form-container {
    max-width: 480px;
    padding-bottom: 20px;
    margin: auto;
}

#card-container {
    padding-left: 2em;
    padding-right: 2em;
    margin-top: 3em;
}

#terms {
    font-size: small;
}

#create-button {
    background: #e5a946;
}
</style>