<template>
    <div class="purple-container">
        <ui-grid style="max-width: 1080px;">
            <ui-grid-cell columns="10" align="middle">
                <h1 class="subtitle light"> {{ $t('home.quote1') }} </h1>
                <h2 class="subtitle light"> {{ $t('home.quote2') }} </h2>
            </ui-grid-cell>
            <ui-grid-cell columns="2">
                <img id="quote" alt="Logo de Ticket Dancer" src="@/assets/quote.png" />
            </ui-grid-cell>
        </ui-grid>
    </div>
    <h1 class="subtitle"> {{ $t('home.subtitle1') }}</h1>
    <ui-grid style="max-width: 1248px;">
        <ui-grid-cell columns="4">
            <td-tile :title="step1title" :message="step1message" :img-url="require('@/assets/home/1.1.svg')"
                :img-height="150" :img-width="150"></td-tile>
        </ui-grid-cell>
        <ui-grid-cell columns="4">
            <td-tile :title="step2title" :message="step2message" :img-url="require('@/assets/home/1.2.svg')"
                :img-height="150" :img-width="150"></td-tile>
        </ui-grid-cell>
        <ui-grid-cell columns="4">
            <td-tile :title="step3title" :message="step3message" :img-url="require('@/assets/home/1.3.svg')"
                :img-height="150" :img-width="150"></td-tile>
        </ui-grid-cell>
    </ui-grid>
    <div class="orange-container">
        <h1 class="subtitle light"> {{ $t('home.subtitle2') }}</h1>
        <ui-grid style="max-width: 1080px;">
            <ui-grid-cell columns="3">
                <ui-card class="card fixedH" v-shadow.transition="[2, 16]">
                    <td-tile :message="realTimeReports" :img-url="require('@/assets/home/2.1.svg')" :img-height="100"
                        :img-width="100"></td-tile>
                </ui-card>
            </ui-grid-cell>
            <ui-grid-cell columns="3">
                <ui-card class="card fixedH" v-shadow.transition="[2, 16]">
                    <td-tile :message="discountCodes" :img-url="require('@/assets/home/2.2.svg')" :img-height="100"
                        :img-width="100"></td-tile>
                </ui-card>
            </ui-grid-cell>
            <ui-grid-cell columns="3">
                <ui-card class="card fixedH" v-shadow.transition="[2, 16]">
                    <td-tile :message="customTickets" :img-url="require('@/assets/home/2.3.svg')" :img-height="100"
                        :img-width="100"></td-tile>
                </ui-card>
            </ui-grid-cell>
            <ui-grid-cell columns="3">
                <ui-card class="card fixedH" v-shadow.transition="[2, 16]">
                    <td-tile :message="qrScan" :img-url="require('@/assets/home/2.4.svg')" :img-height="100"
                        :img-width="100"></td-tile>
                </ui-card>
            </ui-grid-cell>
            <ui-grid-cell columns="3">
                <ui-card class="card fixedH" v-shadow.transition="[2, 16]">
                    <td-tile :message="competitions" :img-url="require('@/assets/home/2.5.svg')" :img-height="100"
                        :img-width="100"></td-tile>
                </ui-card>
            </ui-grid-cell>
            <ui-grid-cell columns="3">
                <ui-card class="card fixedH" v-shadow.transition="[2, 16]">
                    <td-tile :message="diffusion" :img-url="require('@/assets/home/2.6.svg')" :img-height="100"
                        :img-width="100"></td-tile>
                </ui-card>
            </ui-grid-cell>
            <ui-grid-cell columns="3">
                <ui-card class="card fixedH" v-shadow.transition="[2, 16]">
                    <td-tile :message="paymentMethods" :img-url="require('@/assets/home/2.7.svg')" :img-height="100"
                        :img-width="100"></td-tile>
                </ui-card>
            </ui-grid-cell>
            <ui-grid-cell columns="3">
                <ui-card class="card fixedH" v-shadow.transition="[2, 16]">
                    <td-tile :message="focus" :img-url="require('@/assets/home/2.8.svg')" :img-height="100"
                        :img-width="100"></td-tile>
                </ui-card>
            </ui-grid-cell>
        </ui-grid>
    </div>
    <h1 class="subtitle"> {{ $t('home.subtitle3') }}</h1>
    <div class="tiles-container">
        <td-image-text-tile text-align="left" :title="unique" :message="uniqueMessage"
            :img-url="require('@/assets/home/3.1.png')" :img-height="300" :img-width="280"></td-image-text-tile>
        <td-image-text-tile text-align="right" :title="tested" :message="testedMessage"
            :img-url="require('@/assets/home/3.2.png')" :img-height="300" :img-width="280"></td-image-text-tile>
        <td-image-text-tile text-align="left" :title="reliable" :message="reliableMessage"
            :img-url="require('@/assets/home/3.3.png')" :img-height="300" :img-width="280"></td-image-text-tile>
        <td-image-text-tile text-align="right" :title="authentic" :message="authenticMessage"
            :img-url="require('@/assets/home/3.4.png')" :img-height="300" :img-width="280"></td-image-text-tile>
    </div>
    <div class="purple-container">
        <div class="uxp-container">
            <h1 class="subtitle light"> {{ $t('home.userXP') }}</h1>
            <p style="color: white; text-align: center;"> {{ $t('home.userXPMessage') }}</p>
            <ui-grid>
                <ui-grid-cell columns="4">
                    <ui-card class="card" v-shadow.transition="[2, 16]">
                        <td-tile :img-url="require('@/assets/appstore.svg')" :img-height="100"></td-tile>
                        <ui-card-actions full-bleed>
                            <ui-button @click="goToApple">
                                Ticket Dancer™ iOS
                                <template #after>
                                    <ui-icon>arrow_forward</ui-icon>
                                </template>
                            </ui-button>
                        </ui-card-actions>
                    </ui-card>

                </ui-grid-cell>
                <ui-grid-cell columns="4">
                    <ui-card class="card" v-shadow.transition="[2, 16]">
                        <td-tile :img-url="require('@/assets/googleplay.png')" :img-height="100"></td-tile>
                        <ui-card-actions full-bleed>
                            <ui-button @click="goToGoogle">
                                Ticket Dancer™ Android
                                <template #after>
                                    <ui-icon>arrow_forward</ui-icon>
                                </template>
                            </ui-button>
                        </ui-card-actions>
                    </ui-card>
                </ui-grid-cell>
                <ui-grid-cell columns="4">
                    <ui-card class="card" v-shadow.transition="[2, 16]">
                        <td-tile :img-url="require('@/assets/home/4.1.svg')" :img-height="100"></td-tile>
                        <ui-card-actions full-bleed>
                            <ui-button @click="goToWeb">
                                Ticket Dancer™ Web
                                <template #after>
                                    <ui-icon>arrow_forward</ui-icon>
                                </template>
                            </ui-button>
                        </ui-card-actions>
                    </ui-card>
                </ui-grid-cell>
            </ui-grid>
        </div>
        <div class="bullets-container">
            <div class="bullet-container">
                <div class="bullet-item">
                    <img src="@/assets/check_white.png" />
                </div>
                <div class="bullet-item">
                    <p class="message">
                        {{ $t('home.uxp1') }}
                    </p>
                </div>
            </div>
            <div class="bullet-container">
                <div class="bullet-item">
                    <img src="@/assets/check_white.png" />
                </div>
                <div class="bullet-item">
                    <p class="message">
                        {{ $t('home.uxp2') }}
                    </p>
                </div>
            </div>
            <div class="bullet-container">
                <div class="bullet-item">
                    <img src="@/assets/check_white.png" />
                </div>
                <div class="bullet-item">
                    <p class="message">
                        {{ $t('home.uxp3') }}
                    </p>
                </div>
            </div>
            <div class="bullet-container">
                <div class="bullet-item">
                    <img src="@/assets/check_white.png" />
                </div>
                <div class="bullet-item">
                    <p class="message">
                        {{ $t('home.uxp4') }}
                    </p>
                </div>
            </div>
            <div class="bullet-container">
                <div class="bullet-item">
                    <img src="@/assets/check_white.png" />
                </div>
                <div class="bullet-item">
                    <p class="message">
                        {{ $t('home.uxp5') }}
                    </p>
                </div>
            </div>
            <div class="bullet-container">
                <div class="bullet-item">
                    <img src="@/assets/check_white.png" />
                </div>
                <div class="bullet-item">
                    <p class="message">
                        {{ $t('home.uxp6') }}
                    </p>
                </div>
            </div>
            <div class="bullet-container">
                <div class="bullet-item">
                    <img src="@/assets/check_white.png" />
                </div>
                <div class="bullet-item">
                    <p class="message">
                        {{ $t('home.uxp7') }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="yellow-container">
        <h1 class="subtitle light"> {{ $t('home.successStories') }} </h1>
        <td-success-case></td-success-case>
    </div>
</template>

<script>

import TdTile from '@/components/home/TdTile.vue';
import TdImageTextTile from '@/components/home/TdImageTextTile.vue';
import TdSuccessCase from '@/components/home/TdSuccessCase.vue';

export default {
    name: 'TdAbout',
    components: { TdTile, TdImageTextTile, TdSuccessCase },
    computed: {
        quote1() {
            return this.$t('home.quote1')
        },
        quote2() {
            return this.$t('home.quote2')
        },
        step1title() {
            return this.$t('home.step1title')
        },
        step1message() {
            return this.$t('home.step1message')
        },
        step2title() {
            return this.$t('home.step2title')
        },
        step2message() {
            return this.$t('home.step2message')
        },
        step3title() {
            return this.$t('home.step3title')
        },
        step3message() {
            return this.$t('home.step3message')
        },
        realTimeReports() {
            return this.$t('home.realTimeReports')
        },
        discountCodes() {
            return this.$t('home.discountCodes')
        },
        customTickets() {
            return this.$t('home.customTickets')
        },
        qrScan() {
            return this.$t('home.qrScan')
        },
        competitions() {
            return this.$t('home.competitions')
        },
        diffusion() {
            return this.$t('home.diffusion')
        },
        paymentMethods() {
            return this.$t('home.paymentMethods')
        },
        focus() {
            return this.$t('home.focus')
        },
        unique() {
            return this.$t('home.unique')
        },
        uniqueMessage() {
            return this.$t('home.uniqueMessage')
        },
        tested() {
            return this.$t('home.tested')
        },
        testedMessage() {
            return this.$t('home.testedMessage')
        },
        reliable() {
            return this.$t('home.reliable')
        },
        reliableMessage() {
            return this.$t('home.reliableMessage')
        },
        authentic() {
            return this.$t('home.authentic')
        },
        authenticMessage() {
            return this.$t('home.authenticMessage')
        },
        successStories() {
            return this.$t('home.successStories')
        }
    },
    methods: {
        goToApple() {
            window.open('https://apps.apple.com/mx/app/ticket-dancer/id1420355975', 'blank');
        },
        goToGoogle() {
            window.open('https://play.google.com/store/apps/details?id=com.produccionespirinola.ticketdancerapp', 'blank');
        },
        goToWeb() {
            window.open('https://ticketdancer.com.mx', 'blank');
        }
    }
}
</script>

<style scoped>
.subtitle {
    text-align: center;
    margin: 40px;
}

.subtitle.light {
    color: white;
}

.card {
    background: white;
    border-radius: 8px;
}

.card.fixedH {
    height: 250px;
}

.orange-container {
    background-color: #F6855Cee;
    padding: 20px;
}

.yellow-container {
    background-color: #e5a946dd;
    padding-top: 20px;
    padding-bottom: 100px;
}

.purple-container {
    background-color: #652d66dd;
    padding: 20px;
}

.tiles-container {
    margin: 0 auto;
    width: 840px;
    padding-bottom: 40px;
}

.uxp-container {
    margin: 0 auto;
    width: 1060px;
    padding-bottom: 20px;
}

.bullets-container {
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 40px;
    width: 790px;
}

.bullet-container {
    display: flex;
    align-items: center;
}

.bullet-item {
    margin-left: 20px;
    margin-right: 20px;
    color: white;
    font-size: larger;
}
</style>