<template>
  <img id="logo" alt="Logo de Ticket Dancer" src="@/assets/logo-td.png" width="120" />
  <h1>{{ $t("titles.enter") }}</h1>
  <h3> {{ $t('titles.mainSubtitle') }}</h3>
  <external-sign-in />
  <ui-grid style="max-width: 80%;">
    <ui-grid-cell columns="6">
      <login-form />
    </ui-grid-cell>
    <ui-grid-cell columns="6">
      <sign-in-form />
    </ui-grid-cell>
  </ui-grid>
  <h5>
    <a href="https://ticketdancer.com.mx"> {{ $t('user.rights', [(new Date().getFullYear())]) }}</a>
  </h5>
</template>
<script>
import LoginForm from "@/components/user/LoginForm.vue";
import ExternalSignIn from "@/components/user/ExternalSignIn.vue";
import SignInForm from "@/components/user/SignInForm.vue";
import { useMainStore } from '@/stores/main';
import { RECAPTCHA_ACTIONS } from "@/utils/constants";
import commonsApi from "@/api/CommonsApi";
import i18n from "@/i18n"

export default {
  name: "LoginPage",
  components: {
    LoginForm, ExternalSignIn, SignInForm
  },
  async beforeRouteEnter(to, from, next) {

    if (window.grecaptcha) {

      await window.grecaptcha.enterprise.ready(async () => {

        try {
          const token = await window.grecaptcha.enterprise.execute(process.env.VUE_APP_RECAPTCHA_KEY, { action: RECAPTCHA_ACTIONS.PAGE_LOAD_LOGIN_CREATE });
          await commonsApi.checkReCaptcha(RECAPTCHA_ACTIONS.PAGE_LOAD_LOGIN_CREATE, token, process.env.VUE_APP_RECAPTCHA_KEY)
        }
        catch (error) {
          console.error(error)
          this.$router.push("/not-found")
        }
      });
    }

    const mainStore = useMainStore()

    if (mainStore.userLogged) {
      next("/registrar");
    } else {
      mainStore.$patch({ pageTitle: i18n.global.t('titles.main') })
      next();
    }
  },
};
</script>
<style scoped>
h1,
h3,
h5 {
  text-align: center;
  margin-top: 2em;
}

#logo {
  display: block;
  margin: auto;
  padding-top: 40px;
}
</style>