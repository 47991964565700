<template>
    <ui-tabs v-model="active">
        <ui-tab> {{ this.$t('titles.generalInfo') }}</ui-tab>
        <template v-if="!isNewEvent">
            <ui-tab>{{ this.$t('titles.images') }}</ui-tab>
            <ui-tab>{{ this.$t('titles.tickets') }}</ui-tab>
            <ui-tab>{{ this.$t('titles.otherPayments') }}</ui-tab>
            <ui-tab>{{ this.$t('titles.access') }}</ui-tab>
        </template>
    </ui-tabs>

    <ui-panels class="panels-content" v-model="active">
        <ui-panel><event-main-details @cancel-edit="$emit('cancelEditMainDetails')"></event-main-details></ui-panel>
        <template v-if="!isNewEvent">
            <ui-panel><event-images></event-images></ui-panel>
            <ui-panel><event-tabla-boletos></event-tabla-boletos></ui-panel>
            <ui-panel><event-tabla-otros-pagos></event-tabla-otros-pagos></ui-panel>
            <ui-panel><event-tabla-staff></event-tabla-staff></ui-panel>
        </template>
    </ui-panels>
</template>

<script>

import EventMainDetails from '@/components/mis-eventos/EventMainDetails.vue';
import EventImages from '@/components/mis-eventos/EventImages.vue';
import EventTablaBoletos from '@/components/mis-eventos/EventTablaBoletos.vue'
import EventTablaOtrosPagos from '@/components/mis-eventos/EventTablaOtrosPagos.vue';
import EventTablaStaff from '@/components/mis-eventos/EventTablaStaff.vue';

export default {
    components: {
        EventMainDetails,
        EventImages,
        EventTablaBoletos,
        EventTablaOtrosPagos,
        EventTablaStaff
    },
    props: {
        isNewEvent: Boolean
    },
    emits: ['cancelEditMainDetails'],
    data() {
        return {
            active: 0
        };
    }
};
</script>

<style scoped>
.panels-content {
    padding-bottom: 40px;
}
</style>